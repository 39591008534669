@import "../../../../library/src/styles/responsive";
@import "../../../../library/src/styles/dimensions";
@import "../../../../library/src/styles/utility";
@import "../../../../library/src/styles/color";

.ActiveNavigationItem {
  .NavigationItem {
    font-weight: bold;

    color: var(--sidebar-text-active-hover-color) !important;
  }
}

.text-main-color {
  color: var(--text-main-color) !important
}

.border-main-color {
  border-color: var(--text-main-color) !important;
}

.NavigationItem {
  color: var(--sidebar-text-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: var(--sidebar-text-font-size);
  text-overflow: ellipsis;
  border-radius: var(--input-border-radius);
  justify-content: space-between;
  $margin-x: 5px;
  margin: 0 $margin-x 1rem $margin-x;




  &--last {
    margin-bottom: 0;
  }

  &--uppercase {
    text-transform: capitalize;
  }

  &__label {
    margin-left: 5px;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  $item: &;

  &:hover,
  &:active {
    * {
      color: var(--bg-secondary-bg-color) !important;

    }
  }


  &--background-gradient {
    &-yellow {
      @include yellow-background;
      color: var(--title-color-1) !important;
    }

    &-red {
      @include red-background;
      color: var(--title-color-1) !important;
    }
  }

  &:hover:not(&--background-gradient-yellow):not(&--background-gradient-red),
  &--active:not(&--background-gradient-yellow):not(&--background-gradient-red) {
    text-decoration: none;
    color: var(--sidebar-text-active-hover-color) !important;
    background-color: var(--card-primary-bg-color);
  }

  &--active .NavigationItem {
    background-color: var(--card-primary-bg-color);

    color: var(--sidebar-text-active-hover-color) !important;
  }

  &:hover &--background-gradient-yellow,
  &--active &--background-gradient-yellow {
    @include yellow-background;
    color: var(--sidebar-text-active-hover-color) !important;

    svg {
      color: var(--title-color-1) !important;
    }
  }

  &:hover &--background-gradient-red,
  &--active &--background-gradient-red {
    @include red-background;
    color: var(--title-color-1) !important;

    svg {
      color: var(--title-color-1) !important;
    }
  }

  &__icon {
    width: 32px !important;
    height: 32px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;

    &.PointsIcon {
      svg {
        width: 18px !important;
        height: 18px !important;
      }
    }

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 4px;

      &--background {
        background-color: var(--faded-color);

        svg {
          color: var(--title-color-1-inversed);
        }
      }
    }
  }

  &__action {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    max-width: 20px;

    &--purple {
      color: #dd78ff;
    }
  }

  &--expanded {
    background-color: transparent;

    &.NavigationItem--has-dropdown {
      margin: 0 !important;
    }
  }

  &__dropdown {
    //@include transition(max-height 0.5s ease-in-out); //it does not look good
    background-color: var(--body-bg-color) !important;
    max-height: 0;
    overflow: hidden;

    &--expanded {
      //hack with max height for transition. value should be higher than the "proper" menu height
      max-height: 100%;
    }

    .NavigationItem {
      margin: 0 0 0 0 !important;
      padding: 0.5rem 0 0.5rem 1.5rem;
    }
  }
}