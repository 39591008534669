@import "../../../library/src/styles/color";

.ChatBox {
  padding: 16px;
  height: 100%;

  &__wrapper {
    height: 100%;
    padding: 20px 28px;
    border-radius: 10px;

    & .TabGroup {
      display: flex;
      flex-direction: column;

      & .TabGroup__content {
        flex-grow: 1;
        height: calc(100% - 200px);
      }

      .MuiTab-root {
        padding-left: 6px;
        padding-right: 6px;
        overflow: initial;
      }

      .MuiTabs-indicator {
        bottom: 5px !important;
      }
    }
  }

  &__loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.ChatTab {
  position: relative;
  padding-bottom: 55px;
  height: 100%;
  text-align: left !important;

  &__container {
    min-height: 0;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: var(--title-color-1);
    margin: 2px 0;
  }

  &__input-container {
    position: absolute;
    bottom: 0;
    padding: 33px 10px 0 10px;
    width: 100%;
  }

  &__quick-panel {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 20px;
    text-align: left;
    background-color: var(--primary-bg-color);
    padding: 2px;
    color: var(--title-color-1);
    font-size: 12px;
    transition: height 0.2s linear;
    overflow-y: hidden;

    &--open {
      height: 240px;
      overflow-y: auto;
    }
  }

  &__input-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
  }

  &__input-icon {
    color: var(--title-color-1) !important;
    padding: 12px 4px !important;
    min-width: 48px;
    min-height: 48px;

    &__submit {
      margin-left: 5px !important;
    }

    img {
      width: 20px;
    }
  }

  &__input {
    background: transparent;
    border: none;
    color: var(--title-color-1);
    flex-grow: 1;
    font-size: 12px;

    &::placeholder {
      color: rgba(var(--title-color-1-rgb), 0.2);
    }

    &:focus {
      outline: none;
    }
  }

  &__input__label {
    color: var(--title-color-1);
    font-size: 12px;
    margin: auto;
    justify-content: space-around;
    padding-left: 5px;
  }

  &__whisper-online-status {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 25px;
    background-color: var(--primary-bg-color);
    font-size: 12px;
    color: var(--title-color-1);
  }

  &__quick-reply {
    width: 100%;
    padding: 4px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(var(--title-color-1-rbg), 0.1);

      .ChatTab__quick-content {
        color: var(--title-color-1);
        width: 40%;
        text-align: left;
      }
    }

    &__error {
      border: 1px solid rgba(var(--title-color-1-rbg), 0.3);
      background-color: rgba(var(--title-color-1-rbg), 0.3);
      border-radius: 5px;
      width: 90%;
      height: 40px;
      margin: 4px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 13px;
        text-align: left;
        margin-left: 6px;
        margin-top: 8px;
      }
    }
  }

  &__quick-icon {
    padding: 0 !important;
    min-height: 26px;

    & .material-icons {
      color: var(--strong-color);
      margin: 0 6px;
      font-size: 14px;

      &:hover {
        background-color: rgba(var(--title-color-1-rbg), 0.1);
        color: var(--strong-color);
      }
    }
  }

  #quickReplies_checkbox {
    flex-grow: 0;
  }

  .ChatTab__quick-reply__banned {
    color: red;
    font-size: 10px;
    text-align: left;
    margin-left: 42px;

    &__chat {
      margin-left: 0;
      color: red;
      position: absolute;
      bottom: -10px;
      left: 86px;
    }
  }

  .Checkbox.MuiCheckbox-root {
    color: var(--title-color-1) !important;
    fill: var(--title-color-1) !important;

    &.Mui-disabled {
      color: $gray-2 !important;
    }
  }
}

.ChatItem {
  padding: 4px 10px;
  font-size: 12px;
  color: var(--title-color-1);
  position: relative !important;

  &--admin {
    .ChatItem__from {
      color: red !important;
    }
  }

  &__from {
    &--model {
      color: $orange-1;

      svg {
        fill: $orange-1 !important;
      }
    }
  }

  &__box {
    display: flex;
    height: 60px;
    align-items: center;
    padding: 15px;
    position: relative;
    margin: 2.5px 0;
    color: var(--title-color-1);

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(var(--primary-bg-color-rgb), 0.2);
    }

    > img {
      width: 30px;
      margin-right: 15px;
    }
  }

  &__BountyIcon {
    max-width: 20px;
    margin-right: 0.5rem;

    img {
      width: 100%;
    }
  }

  &__Username {
    position: relative;
    display: inline-block;
  }

  &__UsernameLink {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: currentColor;
      margin-right: 4px;
    }
  }

  &__UsernameWrapper {
    background: white !important;
    padding: 0 !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    overflow: hidden;
    border-width: 0px !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    min-width: 170px;
    // max-width: 260px;
    z-index: 1000;
  }

  &__UsernameTooltip {
    flex-direction: row;
    flex-wrap: wrap !important;
    background: white;
    font-size: 12px;

    &.ChatItem__MemberListTooltip {
      font-size: 1em;

      .ChatItem__UsernameTooltipHeader {
        font-size: 1.1em;
      }

      .ChatItem__UsernameTooltipBadge {
        margin: 3px 0;
      }
    }

    &Header {
      background-color: #e1e1e1;
      border-bottom: 1px solid #cdcdcd;
      padding: 5px 10px;
      font-weight: bold;
    }

    &Close {
      position: absolute;
      top: 5px;
      right: 10px;
      background-color: #c8c8c8;
      color: white;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      cursor: pointer;

      & > span {
        width: 20px;
        height: 20px;
      }
    }

    &Body {
      padding: 0px;
    }

    &Section {
      border-top: 1px solid #cdcdcd;
      padding: 4px 14px;
    }

    &Badge {
      width: 100%;
      margin: 3px 10px;
      overflow-wrap: break-word !important;
      font-weight: bold;

      &:first-child {
        border-top: none;
      }

      &--TopAdmirer {
        color: #ff3333;
      }

      &--Bounty {
        color: #ffb1c6;
      }

      &--HasToken {
        color: #00cccc;
      }

      &--Premiere {
        color: #ff007f;
      }

      &--FanClub {
        color: #009900;
      }

      &--SpendingTier-1 {
        color: #0000cc;
      }

      &--SpendingTier-2 {
        color: #ad33ff;
      }

      &--SpendingTier-3 {
        color: #5c0099;
      }
      &--HighValue {
        color: #FFA500;
      }
    }
  }

  &__UsernameTooltipHeader {
    .user-country {
      margin-left: 8px;
      margin-right: 25px;
    }
  }
}

.ChatPrivate {
  height: 100%;

  &__Loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;

    &-Text {
      text-align: center;
      font-size: 12px;
    }
  }
}

.ChatFontSizePopover {
  background: var(--primary-bg-color);
  padding: 5px;
  color: black;
  font-size: 12px;
  width: 70px;
  max-height: 150px;
  overflow: auto;

  &__tailwind-sizes {
    width: 155px;
    text-align: end;
  }

  .fontSizeItem {
    padding: 0 12px;
    line-height: 25px;
    cursor: pointer;

    &.selectedFontSize {
      background: var(--primary-bg-color);
    }
  }
}

.ChatWhisper {
  height: 100%;
  position: relative;

  .TabGroup__Tabs {
    padding: 5px 0;

    .MuiTabs-scrollButtons {
      color: white !important;
    }

    .MuiTabs-indicator {
      display: none !important;
    }

    .MuiTab-wrapper {
      border: 1px solid #515087;
      background-color: #383b6e;
      padding: 0 12px;
    }

    .MuiBadge-anchorOriginTopRightRectangle {
      right: -10px !important;
    }
  }

  &__close {
    position: absolute;
    top: -10px;
    right: -18px;
    background-color: #383b6e;
    border: 1px solid #515087;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons {
      font-size: 12px;
    }
  }
}

.TranslationPopover {
  background: var(--primary-bg-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 191px;
  justify-content: flex-start;
  padding: 10px;
  align-items: center;

  &__TranslationItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
    cursor: pointer;
    margin: 5px 0;
    border: 1px solid transparent;
    padding: 5px;

    span {
      font-size: 12px;
      color: var(--title-color-1);
    }
  }
}

.ChatNotification {
  padding: 4px 10px 4px 0px;
  margin-top: 2px;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  color: var(--text-main-color);

  & a {
    color: $orange-1 !important;
  }

  > div {
    font-weight: normal;
  }
}

.notificationTipped {
  background-color: #6df860;
  color: black;

  svg {
    color: white;
    fill: white;
  }

  &.background-muted {
    background-color: #e8ffed;

    svg {
      color: black;
      fill: black;
    }
  }
}

.notificationGift {
  background-image: linear-gradient(90deg, #2abd76 0%, #124a87 100%);
  color: black;

  > svg {
    color: white;
    fill: white;
  }

  &.background-muted {
    background: #dbebf6;

    svg {
      fill: black;
    }
  }
}

.notificationBuzz {
  background: linear-gradient(90deg, #f261ff 0%, #412fc4 100%);
  color: white;

  svg {
    fill: white;
    width: 16px;
    height: 16px;
  }

  &.background-muted {
    background: #ffe3fe;
    color: black;
    
    svg {
      fill: black;
    }
  }
}

.notificationSuperBuzz {
  background: linear-gradient(90deg, #f261ff 0%, #412fc4 100%);
  color: white;

  svg {
    fill: white;
    width: 16px;
    height: 16px;
  }

  &.background-muted {
    background: #ffe3fe;
    color: black;

    svg {
      fill: black;
    }
  }
}

.notificationConnexion {
  background-image: linear-gradient(90deg, #ff0048 0%, #580096 100%);
  color: white;

  svg {
    color: white;
    fill: white;
  }
}

.notificationWheel {
  background-image: linear-gradient(90deg, #cfe9ff 0%, #33496b 100%);
  color: black;

  .CustomIcon {
    padding: 0;
  }

  svg {
    color: white;
    fill: white;
  }

  &.background-muted {
    background-image: none;
    background-color: #e2e6ea;
  }
}

.notificationBuzz img,
.notificationSuperBuzz img {
  height: auto;
}

.emoji-picker-react {
  border: 0px !important;
  box-shadow: none !important;
}

.truncate-username {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 50% !important;
}

@keyframes newtabeffect {
  from {
    opacity: 0.15;
  }
  to {
    opacity: 1;
  }
}

.newtabanim {
  animation-name: newtabeffect;
  animation-duration: 0.5s;
  animation-iteration-count: 11;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

.BroadcastChatTabs {
  .tab-container {
    &:last-of-type {
      margin-right: unset;
    }
  }
}

@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url(../../../../src/library/public/images/fonts/NotoColorEmoji.ttf);
}

body .user-country {
  &__flag {
    font-family: 'NotoColorEmojiLimited', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  }

  &__name {
    font-size: 12px;
    color: black;
    line-height: 12px;
    margin-left: 4px;
  }
}
