@import "../../../library/src/styles/responsive";

.PrizesList {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(175px, 1fr) );
  grid-auto-flow: row;
  align-items: start;
  width: auto;
}
@include desktop{
  .PrizesList{
    width: 350px;
  }
}
