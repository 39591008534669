.BroadcastContainer {
  overflow-x: hidden;

  &,
  &__column {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .TokenIcon {
    width: 1em;
    height: 1em;
  }

  &__LeaveModal {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: var(--title-color-1);
  }
}

.MuiPaper-root,
.MuiPaper-elevation0,
.MuiCard-root,
.Card,
.MuiPaper-rounded {
  overflow: visible !important;
}

.MuiPaper-elevation0,
.Card__content__enter-types,
.Card--primary {
  height: auto;

  .MuiCardContent-root {
    &.Card__content {
      &.Card__content__enter-types {
        height: auto;
        overflow: auto;
      }
    }
  }
}

.caret-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(156, 163, 175, var(--tw-bg-opacity));
  position: absolute;
  left: 50%;
  top: -0.5rem;
  transform: translateX(-50%);
}

.broadcasting-settings-modal {
  @media (min-width: 1200px) and (max-width: 1279px) {
    >* {
      transform: scale(0.9);
    }
  }

  @media (min-height: 500px) and (max-height: 600px) {
    >* {
      transform: scale(0.7);
    }
  }
}