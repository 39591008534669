.Media {
  $this: &;

  &--has-border-radius {
    border-radius: var(--media-border-radius);
    #{$this}__content {
      border-radius: var(--media-border-radius);
    }
  }

  &--shape {
    &-soft-square,
    &-square {
      position: relative;
      width: 100%;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    img {
      width: 100%;
    }
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}