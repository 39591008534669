.BroadcastMemberVerifyPanel {
  input[type="checkbox"] {
    accent-color: rgba(236, 72, 153);
  }

  button {
    &:disabled {
      cursor: initial;
    }
  }
}
