@import '../../../../library/src/styles/color';

.member-gallery-tooltip-popper {
  max-width: 500px;
  background-color: var(--primary-bg-color);
  border-radius: 10px;

  .MuiTooltip-tooltip {
    background-color: var(--primary-bg-color);
    width: auto;
    max-width: 410px;
  }
  .MemberGallery {
    img {
      width: 70px;
    }
    span {
      color: var(--title-color-1);
      line-height: 1.2;
      font-size: 12px;
    }
    &__about{
      max-height: 70px;
      overflow: auto;
      margin-top: 10px;
    }
  }
}
