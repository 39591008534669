@import "../../library/src/styles/responsive";
@import "../../library/src/styles/color";

$content-max-width: 1200px;

.Landing {
  height: 100%;
  display: flex;
  flex-direction: column;


  &__highlight {
    position: relative;
    flex-grow: 1;
  }

  &__subtitle-section {
    background-color: #e9e9e9;
    text-align: center;
    text-transform: capitalize;
  }

  &__subtitle {
    font-size: 2.25rem;
    color: #666;
    padding: 10px 0;
    margin: 0;
  }

  &__img-slider {
    height: 381px;
    max-width: 1900px;
    margin: auto;
  }

  &__slider {
    height: 100%;
    position: relative;
    flex-grow: 1;
  }

  &__buttonGroup {
    position: absolute;
    right: 30px;
    top: 50%;
    z-index: 30;
    transform: translateY(-50%);
  }

  &__buttonSwitch {
    width: 15px;
    height: 15px;
    background-color: transparent;
    border-radius: 50%;
    display: block;
    margin-bottom: 12px;
    border: 2px solid var(--primary-bg-color);
    transition: 0.5s;
    cursor: pointer;

    &--Active {
      background-color: var(--primary-bg-color);
    }

    &--Second {
      border: 2px solid var(--header-bg-color);
    }

    &--ActiveSecond {
      background-color: var(--header-bg-color);
    }
  }

  &__sliderBottomDes {
    position: absolute;
    z-index: 20;

    &--right {
      right: 1rem;
      bottom: 1rem;
      text-align: right;
    }

    &--left {
      left: 1rem;
      bottom: 1rem;
    }
  }

  &__notification {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20;
    color: gray;
    padding-right: 15px;
  }

  &__footer {
    background-color: var(--primary-bg-color);

    &--copyright {
      padding-top: 30px;
      padding-bottom: 30px;

      a {
        margin: 0 10px;
      }
    }
  }

  &__studio-footer {
    background-color: #e9e9e9;

    &--copyright {
      padding-top: 30px;
      padding-bottom: 30px;

      a {
        margin: 0 10px;
      }
    }
  }

  @include desktop {
    &__sliderBottomDes {
      &--left {
        left: 3rem;
      }

      &--right {
        right: 3rem;
      }
    }

    &__footer {
      &__copyright {
        padding: 40px 0;
      }
    }
  }
}

.LandingCover {
  width: 100%;
  background-color: #e9e9e9;
  padding: 15px 0px;

  @screen md {
    padding: 1rem;
  }

  @screen sm {
    padding: 1rem;
  }

  @screen xs {
    padding: 1rem;
  }

  &__content {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    max-width: $content-max-width;

    @screen 2xl {
      width: 60%;
    }
  }



  &__block {
    position: relative;
    width: 33%;

    @screen md {
      width: 100%;
    }

    @screen sm {
      width: 100%;
    }

    @screen xs {
      width: 100%;
    }

    padding: 10px 10px 20px 30px;

    &--arrow-border {
      border-width: 0 2px;
      border-color: #a9a9a9;
      border-style: solid;

      @screen md {
        border-width: 0;
      }

      @screen sm {
        border-width: 0;
      }

      @screen xs {
        border-width: 0;
      }
    }
  }

  &__title {
    color: #303257;
    font-size: 3rem;
    font-weight: 300;
    margin: 0 0 20px 0;
  }

  &__text {
    color: #303257;
    font-size: 1.125rem;

    &>ul>li {
      color: #303257 !important;
      font-size: 1.125rem;
      line-height: 1.75rem;
      padding: 2px 0;
    }
  }

  &__link {
    cursor: pointer;
  }

  &__list {
    padding: 0;

    &-item {
      font-size: 1.875rem;
      list-style-type: none;

      &--phone,
      &--mail,
      &--obs,
      &--chat {
        padding: 0px 0 3px 30px !important;

        @screen 2xl {
          padding: 0px 0 3px 40px !important;
        }
      }

      &--phone {
        background: url(https://secureimage.securedataimages.com/images/cams/cams2/images/50x50_phone.svg) no-repeat left top;
      }

      &--mail {
        background: url(https://secureimage.securedataimages.com/images/cams/cams2/images/50x50_email.svg) no-repeat left top;
      }

      &--obs {
        background: url(https://secureimage.securedataimages.com/images/cams/cams2/images/50x50_OBScamera.svg) no-repeat left top;
      }

      &--chat {
        background: url(https://secureimage.securedataimages.com/images/cams/cams2/images/50x50_LiveChat.svg) no-repeat left top;
      }
    }
  }
}

.LandingJoinBanner {
  background-color: #151a39;
  overflow: hidden;
  min-height: 430px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 1.125rem;
  padding: 1rem;

  &__content {
    max-width: $content-max-width;
  }

  &__text-block {
    margin-bottom: 42px;
    text-align: left;
  }

  &__text {
    color: #fff !important;
    line-height: 30px;
    font-size: 1.125rem;
    padding-bottom: 10px;
  }
}

.LandingSlider {
  &__text {
    margin: 0 auto;
    color: #fff;
    box-sizing: border-box;
    position: absolute;
    width: 45%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 55%;

    &--1,
    &--2,
    &--3 {
      @screen sm {
        width: 100%;
        left: 2rem;
      }
    }

    &--white {
      color: #fff !important;
    }
  }

  &__title {
    font-size: 3rem;
    font-weight: 500;
    text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
  }

  &__text-body {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 30px;
    width: 80%;
    text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
  }

  &__btn {
    z-index: 1000;
    width: 38%;
    margin: 45px 10px 20px 0 !important;
    display: inline-block !important;

    &--learn-more {
      color: white !important;
    }
  }
}

.StudioLanding {
  .LandingCover__title {
    margin-right: 50px;
    color: black !important;
  }

  &__cover {
    width: 100%;
  }

  &__wrapper {
    flex-direction: row;

    @screen sm {
      flex-direction: column;
    }
  }

  &__info {
    flex: 2;
  }

  &__image {
    flex: 1;
  }

  &__content {
    width: 100%;
    max-width: 500px;
  }
}

.dimelo-chat-wrapper .dimelo-chat-bubble {
  position: absolute !important;

}

#dimelo_chat_N2I5NTZmOTEtZWQ5Ni00OTJiLWI4MDctMGNiNzBiMzQ4ODdl_window {
  position: absolute !important;
  width: 100% !important;
}

#dimelo_chat_N2I5NTZmOTEtZWQ5Ni00OTJiLWI4MDctMGNiNzBiMzQ4ODdl_container {
  position: absolute !important;
}