.PayoutDropdownButton {
    width: 220px !important;
    overflow: hidden;
}

.PayoutDropdownContent {
    max-height: 80vh;
    overflow-y: auto;
    width: 220px;
}

.PayoutDropdownHeader {
    border-bottom: 1px solid var(--body-color-1);
    padding: 20px 0;
}