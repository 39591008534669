$black: #000000;
$white: #fff;

$blue-0: #000444;
$blue-1: #101233;
$blue-2: #161833;
$blue-3: #21244d;
$blue-4: #262a4f;
$blue-5: #2c325c;
$blue-6: #2e2b66;
$blue-7: #465bca;
$blue-8: #3B70EB;
$blue-9: #3498db;
$blue-10: #636a95;
$blue-11: #6C708D;
$blue-12: #7e8bd9;
$blue-13: #8c94c1;
$blue-14: #9092A6;
$blue-15: #58bfd6;
$blue-16: #4299E1;
$blue-17: #d5e8ff;
$blue-18: #ECECF8;
$blue-19: #eeeefa;
$blue-20: #22a892;
$blue-21: #d5d5e1;
$blue-22: #2d315b;
$blue-23: #111124;
$blue-24: #213f85;
$blue-25: #383e75;
$blue-26: #d3d6ff;
$blue-27: #4D516E;
$blue-28: #373a75;
$blue-29: #42476D;
$blue-30: #1e1f47;
$blue-31: #6C739F;
$blue-32: #1E1C40;
$blue-33: #515087;
$blue-34: #383b6e;

$orange-1: #ef951b;
$orange-2: #E69F1C;
$orange-3: #FFB03B;
$orange-4: #ff9054;
$orange-5: #ffa05d;
$orange-6: #FD7E14;
$orange-7: #FFA500;
$orange-8: #D67C02;
$orange-9: #e8802d;

$yellow-1: #ffe205;
$yellow-2: #fcc21b;
$yellow-3: #ffeb7a;
$yellow-4: #eddc1f;

$red-1: #ff1744;
$red-2: #d41c50;
$red-3: #cd264f;
$red-4: #852121;
$red-5: #E0343C;
$red-6: #FF455B;
$red-7: #B30000;
$red-8: #99325b;
$red-9: #f52c5b;
$red-10: #ff0000;

$gray-1: #999;
$gray-2: #a5a5a5;
$gray-3: #b3b3b3;
$gray-4: #c1c1c1;
$gray-5: #c5c5c5;
$gray-6: #ebebeb;
$gray-7: #DDD;
$gray-8: #f5f5f5;
$gray-9: #f5f5f5;
$gray-10:#cccccc;
$gray-11:#9F9F9F;
$gray-12:#8b8b94;
$gray-13:#666;
$gray-14: #737373;

$green-1: #28a745;
$green-2: #67d658;
$green-3: #3EF66E;
$green-4: #a3f321;

$pink-1: #bc3c76;
$pink-2: #fa4182;
$pink-3: #ff5497;

$purple-1: #E026D7;
$purple-2: #FC3BFF;
$purple-3: #9d3171;

$grey1: #8C94C0;
$grey2: #B2C2DC;

.bg-go-live-bg-color {
    background-image: var(--go-live-gradient) !important;
}