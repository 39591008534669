@import "../../../library/src/styles/color";
@import "../../../library/src/styles/responsive";

.BroadcastShowType {
  width: 50%;
}

.BroadcastControl {
  &__Panels {
    div {
      div {
        .BroadcastPreview {
          &__products {
            padding: 0px;
          }
        }
      }
    }
  }

  &__Private {
    background-color: $yellow-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &-title {
      p {
        font-size: 1.5em;
        font-weight: 700;
        padding: 20px 0px;
      }
    }

    &-body {
      p {
        padding: 20px 0 0 0;
      }
    }

    &-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 20px 0 10px 0;

      button {
        width: 40%;
        background-color: $green-4;
        font-size: 1.5em;
        padding: 0 10px;
        border: 1px solid $black;
      }
    }

    &-links {
      display: flex;
      width: 60%;
      justify-content: space-evenly;
      margin-bottom: 30px;
      text-decoration: underline;

      &-item {
        cursor: pointer;
        flex-grow: 1;
        width: 50%;
        text-align: center;
      }
    }

    &-footer {
      display: flex;
      margin-bottom: inherit;

      p {
        padding-left: 10px;
      }
    }

    &-tooltip {
      &-decline {
        max-width: 250px;
      }
    }
  }
}

.BroadcastPreview {
  position: relative;

  &__StartStreamingButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 40px;
    border-radius: 10px;
    margin: 10px 0;

    &Icon {
      border: 2px solid black;
      width: 26px;
      height: 26px;
      border-radius: 4px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      svg {
        width: 26px;
        height: auto;
      }
    }
  }

  &__RecordStreaming {
    &Container {
      padding: 5px;
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: rgba(243, 244, 246, 0.7);
      border-radius: 10px;
      z-index: 100;
    }

    &Countdown {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      width: 100px;
      height: 100px;
      color: white;
      z-index: 9999999;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &__RecordButton {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1px;
    border: 2px solid black;
    border-radius: 4px;

    &--Recording {
      border: 2px solid red;

      .BroadcastPreview__RecordButtonCircle {
        background-color: red;
        border-radius: 0px;
      }
    }

    &Circle {
      background-color: black;
      border-radius: 20px;
      transition: border-radius 0.2s linear;
      width: 1rem;
      height: 1rem;
    }
  }

  &__title {
    padding-bottom: 15px;
  }

  &__card {
    justify-content: flex-end;
    background-color: $white !important;
  }

  &__card-closed {
    height: 30px;
    display: flex;
    background-color: $white !important;
  }

  &__products {
    margin-bottom: 20px;

    &-title {
      font-weight: 600;
      padding: 15px 0px;

      &-price {
        padding-bottom: 0px;
        font-weight: 600;
      }
    }

    &-heading {
      padding-bottom: 10px;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .MuiGrid-spacing-xs-5 {
      flex-direction: column;
      align-items: center;

      button {
        width: 120px;
        max-width: 200px;
        background-color: var(--card-primary-bg-color);
      }

      .MuiGrid-grid-md-6 {
        max-width: 100%;
      }
    }

    .Product {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      height: 50px;

      &__wof {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        &-price {
          display: flex;
          flex-direction: row;
          padding: 0;
          align-items: center;
          font-size: 1rem;
        }

        &-start {
          border: 1px solid $black;
          width: 90px;
          height: 30px;
          margin-left: 10px;
          background-color: var(--card-primary-bg-color);
        }

        &-tipJar {
          width: 30% !important;
          display: flex;
          flex-direction: column;
          align-items: center;

          div {
            border: 1px solid $black;
            width: 90px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              font-size: 1.5em;
            }
          }

          p {
            font-size: inherit;
            font-weight: unset;
          }
        }

        &-spin {
          border: 1px solid $black;
          width: 90px;
          height: 30px;
          background-color: var(--card-primary-bg-color);
          margin-top: 10px;
        }

        .ProductDropdown {
          padding: 0px;

          .MuiFormControl-root,
          .MuiTextField-root,
          .Dropdown,
          .Dropdown--small,
          .MuiFormControl-marginDense,
          .MuiFormControl-fullWidth {
            padding: 0px;
            background-color: chocolate !important;
          }
        }
      }
    }

    a {
      color: black;
      font-weight: 700;
      text-decoration: revert;
    }
  }

  &__expand {
    width: 100%;
    justify-content: flex-end !important;
    background-color: $white !important;
    outline: none !important;

    svg {
      transform: rotate(0deg);
    }
  }

  &__expand-open {
    width: 100%;
    justify-content: flex-end !important;
    background-color: $white !important;
    padding: 0px 12px !important;
    outline: none !important;

    svg {
      transform: rotate(180deg);
    }
  }

  &__allow-private {
    font-size: 12px;
    color: var(--title-color-1);
  }

  &__switch {
    height: 22px !important;
    margin: 0 3px !important;
    padding: 0px 2px !important;
    width: 40px !important;

    & > span {
      width: 15px;
      border-radius: 15px !important;
      margin-top: 1px;
    }
  }

  &__icon-button {
    padding: 5px !important;
    color: rgba(var(--title-color-1-rgb), 0.8) !important;

    &--help {
      color: var(--title-color-1) !important;
    }
  }

  &__preview-box {
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--title-color-1);
    position: relative;
  }

  &__videoContainer {
    width: 100%;
    height: 100%;
    position: relative;

    .BroadcastPreview__streamTypeSwitcher {
      display: none;
      z-index: 10;
    }

    &:hover {
      .BroadcastPreview__streamTypeSwitcher {
        display: initial;
      }
    }
  }

  &__video {
    width: 100%;
    max-height: 100%;
    display: none;

    &--started {
      display: initial;
    }
  }

  &__quality-text-normal {
    font-size: 12px;
    color: var(--title-color-1);
    margin-right: 3px;
  }

  &__quality-icon-normal {
    color: var(--title-color-1);
  }

  &__quality-text-good {
    font-size: 12px;
    color: $green-1;
    margin-right: 3px;
  }

  &__quality-text-bad {
    font-size: 12px;
    color: $red-1;
    margin-right: 3px;
  }

  &__quality-icon-good {
    color: $green-1;
  }

  &__quality-icon-bad {
    color: $red-1;
  }

  &__stop-streaming {
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 3rem;
    }
  }

  &__start-streaming {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20%;
    width: 50%;
    left: 25%;
    height: auto;
    bottom: 20%;
    flex-direction: column;

    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-play {
        width: 100px !important;
        height: auto !important;
        cursor: pointer;
      }
    }

    &-text {
      padding-bottom: 10px;
      font-size: 2em;
      font-weight: bold;
    }
  }

  &__test-play {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__obs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $black;
    padding-bottom: 20px;
  }

  &__webrtc-initializing {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 82%;
    display: flex;
    z-index: 999999;
    flex-direction: column;

    &-spacer {
      flex-grow: 1;
    }
  }
}

.Dropdown__paper,
.Dropdown__paper--small {
  max-height: calc(100% - 332px) !important;
}

.BroadcastStartInstruction {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &__Support {
    text-align: center;
    color: var(--title-color-1);

    & > a {
      color: #ef951b !important;
    }
  }

  &__Waiting {
    color: var(--title-color-1);
    font-size: 15px;
    margin: 5px 0;
  }

  &__OrSection {
    & > span {
      margin: 0 5px;
      font-size: 12px;
    }
  }

  &__StartStreaming {
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 4px solid black;
    width: 150px;
    height: 150px;
    border-radius: 5px;

    svg {
      width: 150px;
      height: auto;
    }

    &:hover {
      background: var(--link-active-hover-color);
      color: var(--title-color-1);
    }
  }

  &__Link {
    color: #ef951b;
    cursor: pointer;

    &:hover {
      color: #ffb700;
    }
  }

  &__Instruction {
    display: flex;
    max-height: 100px;
    height: 80px;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.BroadcastTippingGoal {
  position: absolute;
  display: flex;
  z-index: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 999999;
  right: 10px;
  bottom: 35px;
  width: 80px;
  height: 80px;
  background: linear-gradient(
    90deg,
    rgba(242, 97, 255, 0.8) 0%,
    rgba(88, 191, 214, 0.8) 100%
  );
  border-radius: 50%;

  &.goal-reached {
    background: linear-gradient(
      90deg,
      rgba(11, 143, 35, 0.8) 0%,
      rgba(242, 255, 0, 0.8) 100%
    );
  }

  &__Current {
    display: flex;
    font-size: 18px;
  }

  &__Label,
  &__Goal {
    font-size: 10px;
  }

  &__Goal {
    display: flex;
  }

  .token-icon {
    margin-right: 0.2em;
    font-size: 1em;
  }

  p {
    margin-bottom: 0;
    line-height: 1.2;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.BroadcastOBSTimer {
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute !important;
  background-color: transparent;
  top: 0 !important;
  left: 0 !important;

  &.paused {
    position: static;

    .BroadcastOBSTimer__timer {
      font-size: 80px;
    }
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  &__title {
    font-size: 14px;
  }

  &__subtitle {
    font-size: 14px;
  }

  &__timer {
    font-size: 24px;
  }

  &__video {
    width: 100%;
    height: 100%;
    background-color: transparent !important;
  }
}

$showTypeIndicatorTopPixels: 5px;
$indicatorsLeftPixels: 5px;

.BroadcastShowTypeIndicator {
  top: $showTypeIndicatorTopPixels;

  &--litemode {
    top: $showTypeIndicatorTopPixels + 5px;
    left: 10px !important;
  }
}

.BroadcastRecordingIndicator {
  top: calc(#{$showTypeIndicatorTopPixels} + 31px);

  &--litemode {
    top: calc(#{$showTypeIndicatorTopPixels} + 36px);
    left: 10px !important;
  }
}

.BroadcastShowTypeIndicator,
.BroadcastRecordingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: $indicatorsLeftPixels;
  background: var(--primary-bg-color);
  color: var(--text-main-color);
  padding: 0.2em 0.4em;
  border-radius: 0.3em;
  z-index: 100; // to be visible over obs timer

  .BroadcastShowTypeIndicator__circle,
  .BroadcastRecordingIndicator__circle {
    width: 1em;
    z-index: 9999;
    border-radius: 50%;
    height: 1em;
    display: inline-block;
    margin-right: 0.3em;

    &--green {
      background: #65d185;
    }

    &--red {
      background: #cd264f;
    }

    &--yellow {
      background: #ef951b;
    }

    &--blue {
      background: #008cff;
    }

    &--border-blue {
      background: white;
      border: 2px solid #008cff;
    }

    &--purple {
      background: #86339a;
    }
  }
}

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.BroadcastControl {
  &__Private {
    display: flex;
    flex-direction: column;

    &__Box {
      display: flex;
      align-items: center;

      .MuiRadio-colorSecondary,
      .Mui-checked {
        color: $black !important;
        height: 10px;

        svg {
          left: unset;
        }
      }

      .MuiRadio-colorSecondary,
      .Mui-checked:hover {
        background-color: unset !important;
        height: 35px;
      }
    }
  }
}

div {
  .Dropdown__paper,
  .MuiMenu-paper {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

.BroadcastSettingButtons {
  &--litemode {
    .CustomIcon__shadow::before {
      width: 27px;
      height: 27px;

      @include sm {
        width: 22px;
        height: 22px;
      }
    }
  }
}
