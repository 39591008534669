@import "../../../library/src/styles/dimensions";

.Album {
	&__title {
		background-color: transparent;
	}
}
.arrowLeft.CustomIcon {
	width: 19px;
	height: 19px;
}