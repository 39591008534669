@import '../../styles/_color';

.ProfileInfo {
  display: flex;
  align-items: center;
  font-size: 1rem;

  &__title {
    display: flex;
  }

  &__username {
    color: $white;
    margin-right: 0.5rem;
  }

  &__title,
  &__subtitle {
    &--inline {
      display: inline-flex;;
    }
  }

  &__subtitle {
    display: flex;
    color: var(--faded-color);
    font-size: 1rem;
  }
}