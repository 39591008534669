.ProgressBarCicular {
  transform: scale((-1, 1));

  .circular-progress {
    --size: 100%;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 1px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * 3.14159265358979 * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
    min-width: 23px;
    min-height: 23px;
  }

  .circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
  }

  .circular-progress circle.bg {
    stroke: #9e9d99;
  }

  .circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #ffffff;
  }
}
