@import "../../../library/src/styles/color";

.EarningsTable,
.TierInfoTable {
  &__header {
    background-color: var(--table-header-primary-bg-color);
    h6 {
      font-size: 1rem;
      text-align: unset;
    }
  }
}

.EarningPie {
  position: relative;
  width: 200px;
  height: 200px;

  svg {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    transform: rotate(-90deg);
  }
   
  circle {
    fill: none;
    stroke-width: 32;
    r: 16;
    cx: 16;
    cy: 16;
  }

  &__Inner {
    background-color: var(--primary-bg-color);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.EarningGraphCircle {
  min-width: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: "";
    background-color: var(--primary-bg-color);
  }
}


.TierPie {
  position: relative;
  width: 150px;
  height: 150px;

  svg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    transform: rotate(-90deg);
  }

  circle.base {
    fill: none;
    stroke-width: 2;
    r: 16;
    cx: 16;
    cy: 16;
    stroke: rgba(153, 153, 153, 0.2);
  }

  circle.progress {
    fill: none;
    stroke-width: 8;
    r: 16;
    cx: 16;
    cy: 16;
    position: relative;
    z-index: 10;
    stroke: rgba(249, 20, 82, 0.7);
  }

  &__Inner {
    background-color: var(--primary-bg-color);
    width: 134px;
    height: 134px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}