@import "../../../library/src/styles/responsive";
@import "../../../library/src/styles/dimensions";
@import "../../../library/src/styles/utility";
@import "../../../library/src/styles/color";

.DashboardLayout {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  font-family: $font-family;
  z-index: 1000;
  flex-direction: column;
  background-color: var(--primary-bg-color);
  padding: var(--body-padding);

  &--blur{
    filter: blur(10px);
  }

}

.DashboardModal{
  @apply focus:outline-none;
}
