.PaymentRenewalTermsOfUse {
  width: 100%;
  max-width: 1000px;
  background: white;
  padding: 50px;
  justify-self: flex-start;
  min-height: calc(100vh - var(--header-height));
  overflow-x: hidden;

  // exact replicas from old cams for styling
  li {
    text-align: justify;
  }

  .section-tagline {
    text-decoration: underline;
    font-weight: bold;
  }

  &__copyright {
    list-style-type: none;
  }

  &__address {
    margin-left: 28px;
  }

  &__center_h2 {
    font-size: 14px;
    font-weight: bold;
    padding-top: 6px;
    margin: 0;
  }

  &__hr {
    border-top: 1px solid #ccc;
    display: block;
    overflow: hidden;
    height: 1px;
    font-size: 1px;
    margin-top: 6px;
  }

  &__content1 {
    font-size: 12px;
    padding: 6px 0;
  }

  &__keys {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
  }

  ol,ul {
    list-style: disc;
    margin-left: 1.5rem;
    li {
      margin-left: 1.5rem;
    }
  }

  h4,
  h3{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
  }

  &__updated-date {
    color: #999999;
    text-align: right;
    margin: 0px;
    margin-bottom: 10px;
    padding: 0px;
  }

  a {
    text-decoration: underline;
    color: #ef951b;
    cursor: pointer;

    &:hover {
      color: #ef951b;
    }
  }

  p {
    margin-bottom: 0.5rem;
  }

  .p-title {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
}
