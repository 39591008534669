.RecordedShowsModal {
  border-radius: 20px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 540px;
  position: absolute;
  z-index: 900;
  top: 65px !important;
  left: 15% !important;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

  &__closeIcon {
    text-align: right;
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
  }

  &__paragraph {
    margin-bottom: 20px;
    font-size: 12px;
  }

  @media (max-width: 641px) {
    left: 1% !important;
    width: 98vw;
    margin: auto;
  }
}
