@import "../../../library/src/styles/responsive";
@import "../../../library/src/styles/color";
@import "../../../library/src/styles/dimensions";
@import "../../../library/src/styles/utility";

$content-max-width: 1200px;

.LandingLayout {
  &__header {
    position: relative;
    z-index: 999999;

    &__container {
      min-height: 78px;
    }

    &--guest {
      background-color: #303257;

      .LandingLayout__header__container {
        max-width: $content-max-width;

      }
    }

    &-logo {
      width: 69px;
      fill: var(--header-text-color);
    }

    &-streamray-logo {
      height: calc(var(--header-height) - 20px);
      width: auto;
      fill: var(--logo-color);
    }
  }

  @include desktop {
    &__header {
      padding: 8px 12px;
    }
  }

  &__AuthModal {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: var(--title-color-1);
  }
}