@import 'responsive';

// class format : w-1/2 : 50%
// class format : w-1/10 : 10%
@mixin g-widths($limit){
  @for $i from 1 through $limit{
    .w-#{$i}\/#{$limit}{width: (100% / $limit) * $i;}
  }
}

// class format : w-1/2 : 50%
// class format : w-1/10 : 10%
@mixin sm-g-widths($limit){
  @for $i from 1 through $limit{
    .sm-w-#{$i}\/#{$limit}{
      @media (max-width: #{$screen-sm-max}) {
        width: (100% / $limit) * $i;
      }
    }
  }
}

// class format : w-1/2 : 50%
// class format : w-1/10 : 10%
@mixin md-g-widths($limit){
  @for $i from 1 through $limit{
    .md-w-#{$i}\/#{$limit}{
      @media (max-width: #{$screen-md-max}) {
        width: (100% / $limit) * $i;
      }
    }
  }
}

// class format : w-1/2 : 50%
// class format : w-1/10 : 10%
@mixin lg-g-widths($limit){
  @for $i from 1 through $limit{
    .lg-w-#{$i}\/#{$limit}{
      @media (max-width: #{$screen-lg-max}) {
        width: (100% / $limit) * $i;
      }
    }
  }
}

// class format : w-1/2 : 50%
// class format : w-1/10 : 10%
@mixin xl-g-widths($limit){
  @for $i from 1 through $limit{
    .xl-w-#{$i}\/#{$limit}{
      @media (max-width: #{$screen-xl-max}) {
        width: (100% / $limit) * $i;
      }
    }
  }
}

// class format : h-1/2 : 50%
// class format : h-1/10 : 10%
@mixin g-heights($limit){
  @for $i from 1 through $limit{
    .h-#{$i}\/#{$limit}{
      height: (100% / $limit) * $i;
      max-height: (100% / $limit) * $i;
    }
  }
}

// class format : h-1/2 : 50%
// class format : h-1/10 : 10%
@mixin g-viewport-heights($limit){
  @for $i from 1 through $limit{
    .vh-#{$i}\/#{$limit}{
      height: (100vh / $limit) * $i;
      max-height: (100vh / $limit) * $i;
    }
  }
}

$width-segmentations: [10, 12, 16, 18];
@each $segmentation in $width-segmentations{
  @include g-widths($segmentation);
}
@each $segmentation in $width-segmentations{
  @include sm-g-widths($segmentation);
}
@each $segmentation in $width-segmentations{
  @include md-g-widths($segmentation);
}
@each $segmentation in $width-segmentations{
  @include lg-g-widths($segmentation);
}
@each $segmentation in $width-segmentations{
  @include xl-g-widths($segmentation);
}

$height-segmentations: [2, 5, 6, 8, 10, 12];
@each $segmentation in $height-segmentations{
  @include g-heights($segmentation);
}
@each $segmentation in $height-segmentations{
  @include g-viewport-heights($segmentation);
}

.p-col-gutter {
  padding-left: 10px;
  padding-right: 10px;
}
