@import "../../../library/src/styles/color";
@import "../../../library/src/styles/utility";

.ResetPasswordForm{


  &__notification{
    max-width: 100%;
  }
  &__closeIcon{
    position: absolute;
  }
  &__button{
    
    &--dashboard.Button{
      float: right !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--landing{
      width: 100%;
    }
  }

  &__textField{
    width: 100%;
    &--dashboard{
      max-width: calc((100% - 0.8rem) / 2) !important;  
    }
  }
  &__email{
    font-size: 1.25rem;
    font-weight: bold;
  }
  &__studioEmail{
    margin-top: 34px;
    margin-bottom: 64px;
  }

  &__forgot-password {
    margin:10px 0;
  }
  &__forgot-password-text {
    color: $orange-5;
    &:hover {
      color: $orange-5;
      text-decoration: none;
    }
  }

  &__error {
    color: $orange-5;
  }

}