@import "../../library/src/styles/color";
@import "../../library/src/styles/dimensions";
@import "../../library/src/styles/responsive";
@import "../../library/src/styles/utility";

.RegisterModal {
  @include disableScrollbar();

  h5 {
    font-size: 25px;
  }

  &__submit {
    display: flex;
    justify-content: center;
  }

  &__wrapper {
    background: var(--primary-bg-color);
    color: var(--title-color-1-inversed);
  }

  &__cardTitle {
    font-size: 24px;
    margin-bottom: 12px;
    color: var(--title-color-1);
  }

  &__cardSubtitle {
    font-size: 16px;
    color: var(--title-color-1);
  }

  &__form-scrollarea {
    width: 100%;
    max-height: calc(100vh - 67px);
  }

  &__floating_pagination {
    padding-right: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-main-color);
  }

  &__agreement-explanation {
    &-title {
      margin-top: 1rem;
    }

    &-description {
      margin: 1rem;
      padding: 1rem;
      border: 1px solid var(--header-bg-color);
    }
  }

  &__view-example {
    color: var(--header-bg-color);
  }

  &__studio-country-field {
    margin-top: -3px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    height: 100%;
    overflow: hidden;
    color: var(--title-color-1);
    background-color: var(--primary-bg-color);

    @include md {
      transform: scale(0.9)
    }

    @include sm {
      width: 100%;
    }
  }

  &__button-container {
    max-width: 450px !important;
  }

  &__red-text {
    color: red;
  }

  &__grid {
    text-align: right;
  }

  &__button {
    max-width: 210px;
    width: 100%;

    &.mr-1 {
      margin-right: 5.5px !important;
    }

    &.ml-1 {
      margin-left: 5.5px !important;
    }
  }

  &__uploadRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__uploadDocument {
    width: 50%;
  }

  &__uploadExample {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    width: 50%;
  }
}

.RegisterModalHeader {
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 2px solid var(--header-bg-color);

  @include md {
    margin-top: 20px;
  }

  &__step {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--title-color-1-inversed);

    @include md {
      padding-bottom: 5px;
      font-size: 0.9rem;
    }

    &--active {
      border-bottom: 2px solid var(--header-bg-color);
    }
  }
}

.RegisterModalFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__button-container {
    display: flex;
    justify-content: center;
    width: 600px;

    button {
      max-width: 400px;
      max-height: 50px;
    }
  }

  background-color: var(--header-bg-color);
  padding: 1.2rem 0rem;
  border-top: 1px solid var(--borderColor);
}