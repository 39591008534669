@import "../../library/src/styles/color";
@import "../../library/src/styles/dimensions";

.PreLiveChatFormModal {
  background-position: center center;
  background-size: cover;
  position: fixed;
  flex: 1;

  display: flex;
  align-items: center;
  right: 16px;
  width: 390px !important;

  z-index: 10003;
  border-radius: 10px;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.19);
  bottom: 90px;

  &__form {
    position: relative;
    z-index: 10003;
    padding: 2rem;
    width: 100%;

    border-radius: 10px;
    max-width: $auth-modal-form-width;
    background: var(--primary-bg-color);
    color: var(--title-color-1);
  }
}