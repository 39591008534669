$white: #fff;
$black: #000;

.TailwindSpinner {
  z-index: 999999999999;

  &__spinner {
    &.TailwindSpinner__spinning-circle {
      border: 3px solid var(--loading-indicator-custom-bg-color, var(--loading-indicator-bg-color));
      border-top-color: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
      border-radius: 50%;
      animation: rotation .8s ease infinite;
    }

    &.TailwindSpinner__spinning-circle-double {
      border: 3px solid transparent;
      border-top-color: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
      border-bottom-color: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
      border-radius: 50%;
      animation: rotation .8s ease infinite;
    }

    &.TailwindSpinner__spinning-snake {
      border-top: 3px solid var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
      border-right: 3px solid transparent;
      border-radius: 50%;
      animation: rotation .8s linear infinite;
    }

    &.TailwindSpinner__flipping-rectangle {
      background: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
      animation: flip 1.2s ease infinite;
    }

    &.TailwindSpinner__moving-bars {
      overflow: hidden;
      position: relative;
      height: .5rem;

      &::before {
        content: '';
        position: absolute;
        left: -130%;
        width: 100%;
        height: 100%;
        background: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
        animation: progress 2s linear infinite;
      }

    }

    &.TailwindSpinner__blinking-circle {
      position: absolute;
      top: 45%;
      left: 50%;
      background: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
      border-radius: 50%;
      animation: pulse 1s ease-in-out infinite;
      transform: translate(-50%, -50%) scale(0);
    }

    &.TailwindSpinner__pulsating-circle {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
      }

      &::before {
        background: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
        animation: pulse2 2s ease-in-out infinite;
      }

      &::after {
        background: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
        animation: pulse2 2s 1s ease-in-out infinite;
      }
    }

    &.TailwindSpinner__flipping-rectangle-walking {
      position: relative;
      perspective: 200px;

      &::before {
        display: block;
        content: '';
        width: 50%;
        height: 50%;
        background: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
        animation: 2s flipWalker ease infinite;
      }
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes flip {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
      transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
    }

    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
  }

  @keyframes progress {
    0% {
      left: -130%;
      background: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
    }

    50% {
      left: 130%;
      background: var(--loading-indicator-custom-accent-color, var(--loading-indicator-accent-color));
    }

    51% {
      background: var(--loading-indicator-custom-bg-color, var(--loading-indicator-bg-color));
    }

    100% {
      background: var(--loading-indicator-custom-bg-color, var(--loading-indicator-bg-color));
    }
  }

  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
    }

    100% {
      transform: translate(-50%, -50%) scale(1.0);
      opacity: 0;
    }
  }

  @keyframes pulse2 {

    0%,
    100% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
    }

    50% {
      transform: translate(-50%, -50%) scale(1.0);
      opacity: 0;
    }
  }

  @keyframes flipWalker {
    0% {
      transform: translate(0, 0) rotateX(0) rotateY(0);
    }

    25% {
      transform: translate(100%, 0) rotateX(0) rotateY(180deg);
    }

    50% {
      transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
    }

    75% {
      transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
    }

    100% {
      transform: translate(0, 0) rotateX(0) rotateY(360deg);
    }
  }
}