.tab-hidden {
  display: none;
  transform: translateY(+100%);
}

.tab-active {
  animation: tabActiveAnimation .5s forwards;
  transform: translateY(0);
  display: block;
}

@keyframes tabActiveAnimation {
  0% {
    transform: translateY(+100%);
  }

  100% {
    transform: translateY(0);
  }
}

.text-center {
  >* {
    text-align: center !important;
  }
}