@import "../../library/src/styles/responsive";
@import "../../library/src/styles/color";
@import "../../library/src/styles/utility";

.MessageCenterMenuButton {
  .Kebab-menu {
    top: 50px !important;
    right: 0 !important;
    width: 285px !important;
    padding: 1rem !important;
  }

  .Kebab-menu2 {
    top: 50px !important;
    right: 0 !important;
    width: 285px !important;
    padding: 0 0 1rem 0 !important;
  }

  .kebabIcon {
    position: relative;
    top: 1px;
  }
}

.MessagesReader {
  background-color: #e9eef2;

  &__content {
    ul,
    ol {
      margin: revert;
      padding: revert;
      list-style: revert;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: revert;
    }
  }

  &__tooltip {
    font-weight: bold !important;
    background-color: var(--tertiary-bg-color) !important;
    color: var(--title-color-1) !important;
    padding: 6px 8px !important;
    border-radius: 10px !important;
    margin-left: unset;

    &::before,
    &::after {
      display: none;
    }
  }

  .replyIcon {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: #ef951b;
  }

  .tooltipBox {
    position: absolute;
    width: 160px;
    bottom: 5px;
    right: -75px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 66px;
      border-width: 15px 15px 0;
      border-style: solid;
      border-color: #ffffff transparent;
      display: block;
      width: 0;
    }
  }

  .MessagesReader__GalleryGrid {
    .img-container {
      overflow: hidden;
      max-width: #{"min(420px, 100%)"};

      &:first-child {
        border-top-left-radius: 0.5rem;
      }

      &:nth-child(2) {
        border-top-right-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-right-radius: 0.5rem;
      }
    }

    &-bl {
      border-bottom-left-radius: 0.5rem;
    }

    .MessagesAudioTile {
      color: #84a3ba;
      width: 233px;

      svg {
        fill: #84a3ba;
      }
    }

    &--sender {
      .MessagesAudioTile {
        background-color: #84a3ba;
        color: #ffffff;
        padding-top: 15px;

        svg {
          fill: #ffffff;
        }

        .MessagesAudioPlayer__progress-bar-fill,
        .MessagesAudioPlayer__progress-bar--thumb {
          background: #ffffff;
        }

        .MessagesAudioPlayer__pause {
          color: #ffffff;
        }
      }
    }
  }

  &__attachments {
    height: 120px;

    .img-container {
      width: 185px !important;
      height: 112px;
      display: inline-block !important;
      margin-right: 10px;
      background-color: #000000;

      &:last-child {
        margin-right: 0;
      }
    }

    .img-container__play-icon {
      cursor: unset;
    }
  }

  &Message {
    .Avatar {
      width: 45px;
      height: 45px;

      .initial-avatar {
        font-size: 16px;
      }
    }

    .error-image {
      padding: 8px;
    }

    .MessagesReader__GalleryGrid--single {
      .MessageCenterUnavailableAttachment {
        max-width: 150px;
      }
    }
  }

  &__startConversation {
    .Avatar {
      width: 60px;
      height: 60px;

      .initial-avatar {
        font-size: 26px;
      }
    }
  }

  &__model-details {
    font-size: 16px;
  }

  &__video-duration {
    &-pill {
      background-color: rgba(0, 0, 0, 0.5) !important;
      border-radius: 10px !important;
      color: #ffffff !important;
      margin: 5px;
      padding: 0 3px 1px !important;

      span {
        font-size: 0.73rem !important;
        line-height: 0.825rem !important;
        padding: 2px 0 !important;

        &:nth-child(1) {
          margin: 0 !important;

          .CustomIcon {
            margin-right: 4px !important;
            padding: 0 !important;
          }
        }
      }

      svg {
        width: 0.825rem !important;
        height: 0.825rem !important;
      }
    }

    &-size {
      &-xsmall {
        span {
          font-size: 0.6rem !important;
          line-height: 0.7rem !important;
        }

        svg {
          width: 0.7rem !important;
          height: 0.7rem !important;
        }
      }

      &-small {
        span {
          font-size: 0.73rem !important;
          line-height: 0.825rem !important;
        }

        svg {
          width: 0.825rem !important;
          height: 0.825rem !important;
        }
      }

      &-medium {
        span {
          font-size: 0.825rem !important;
          line-height: 0.925rem !important;
        }

        svg {
          width: 0.925rem !important;
          height: 0.925rem !important;
        }
      }

      &-large {
        span {
          font-size: 0.925rem !important;
          line-height: 1rem !important;
        }

        svg {
          width: 1rem !important;
          height: 1rem !important;
        }
      }
    }
  }

  &Footer {
    .CustomIcon svg {
      fill: #ffffff;
    }
  }
}

.MessagesReader__tab {
  &--active {
    background-color: #dbe7ee !important;
  }
}

.native {
  color: black !important;
}

.bg-lightBlue {
  background-color: #d7e8ee;
}

.MessageNavigatorConversation {
  align-items: flex-start;
  min-height: 60px;

  .ConfirmCircleIcon {
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

//Chat bubble
.msg-container {
  display: inline-flex;
  word-break: break-all;
}

.msg-container:before,
.msg-container:after {
  border-radius: 20px / 10px;
  content: "";
  display: block;
  position: absolute;
}

.msg-container:before {
  border: 10px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: -7px;
  z-index: -2;
}

.msg-container:after {
  border: 8px solid transparent;
  border-bottom-color: white;
  bottom: 0;
  left: -7px;
}

.msg-container-right:before,
.msg-container-right:after {
  right: -7px;
  left: auto;
}

.msg-container-right:after {
  border-bottom-color: #84a3ba;
}

.msg-container-slim:after {
  border-bottom-color: #000000;
}

.msg-container-right.msg-container-right-denied:after,
.msg-container-denied:after {
  border-bottom-color: #ccc;
}

@media (min-width: 640px) and (max-width: 1000px) {
  .filterCheck {
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.img-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-tap-highlight-color: transparent;

  .CloseThickerIcon svg {
    width: 7;
    height: 7px;
    stroke: $black;
    fill: $black;
  }

  .VoiceIcon {
    width: 34px;

    &-member {
      fill: #ffffff;
    }

    &-model {
      fill: #84a3ba;
    }
  }

  &__play-icon {
    opacity: 0.8;
    top: calc(50% - 18px);
    left: calc(50% - 18px);

    svg {
      width: 36px;
      height: 36px;

      fill: #ffffff;
    }
  }
}

.contact-badges {
  .Avatar {
    position: relative;
    top: -8px;
  }
}

.MessageCenterCheckMark {
  .ConfirmCircleIcon svg {
    height: 24px;
    width: 24px;
  }
}

.customUploadError {
  .upload_toast_errorMsg {
    position: relative;

    > * {
      color: white !important;
    }

    ul {
      padding: 0.5rem 0.5rem 0;
      margin-left: 10px;

      li {
        &::before {
          content: "•";
          color: white;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }

        color: white !important;
        list-style: none;
        margin-bottom: 5px;
      }
    }
  }

  button {
    position: absolute;
    top: 7px;
    right: 7px;
  }
}

.exclamationMark-icon {
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #9ca3af;
  border-radius: 100%;
  text-align: center;
  line-height: 15px !important;
  display: block !important;
}

@media (max-width: 1121px) and (min-width: 741px) {
  .searchInput {
    max-width: 70%;

    input {
      max-width: 80%;
    }
  }
}

.MessageAttachmentPricingModal {
  max-width: 400px;
  max-height: 550px;
  height: 80vh;

  &__compact {
    height: auto;
  }

  &__image {
    background-color: #000000;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
}

.MessageConversationDeleteModal {
  font-size: 14px;

  li,
  span {
    font-size: 14px;
  }

  h5 {
    font-size: 18px;
  }
}

.MessageCenterReaderUploadingTile {
  text-wrap: wrap;
  background-color: #84a3ba !important;
  color: #ffffff;

  span:not([class*="material"]) {
    font-size: 12px;
    padding: 0 5px;
    line-height: normal;
  }

  &__progress-bar {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    padding: 2px;
    background: #ffffff;
    overflow: hidden;

    &-container {
      padding: 0 20px;
      width: 100%;
      margin: 5px auto;
    }

    &-fill {
      background-color: #ffc638;
      width: 100%;
      height: 100%;
    }
  }
}

.MessagesAudioPlayer {
  position: relative;

  svg {
    fill: #ffffff;
  }

  audio {
    display: none;
  }

  &__progress-bar {
    cursor: pointer;
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background: rgba(235, 235, 235, 0.6);
    display: flex;
    flex-direction: row;
    align-items: center;

    &-container {
      width: 100%;
      position: relative;
    }

    &-fill {
      background-color: #84a3ba;
      width: 100%;
      height: 100%;

      &-playing {
        transition: width 0.2s linear;
      }
    }

    &--thumb {
      height: 13px;
      width: 13px;
      margin-left: -7px;
      border-radius: 50%;
      background: #84a3ba;
      cursor: pointer;
    }
  }

  &__pause {
    color: #84a3ba;
    width: 36px !important;
  }
}

.MessagesAudioTile {
  color: #ffffff;

  .MessagesAudioPlayer {
    min-height: 40px;
  }

  .WaveFormIcon {
    svg {
      width: 100%;
      height: auto;
    }
  }
}

.MessagesAudioRecorder {
  padding: 0 15px 20px;

  &__waveform {
    &--wrapper {
      height: 20px;
    }

    &--bar {
      transform: scaleY(0.1);
      height: 100%;
      width: 100%;
      background: #000000;
      animation-duration: 1.2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      border-radius: 8px;

      &-quiet {
        animation-name: WaveFormQuiet;
      }

      &-normal {
        animation-name: WaveFormNormal;
      }

      &-loud {
        animation-name: WaveFormLoud;
      }
    }

    &--static {
      .MessagesAudioRecorder__waveform--bar {
        animation-iteration-count: 0;

        &-quiet {
          transform: scale((0.3));
        }

        &-normal {
          transform: scale((0.6));
        }

        &-loud {
          transform: scale((1));
        }
      }
    }
  }

  &__container {
    overflow: hidden;
    min-height: 51px;

    &--preview {
      min-height: 86px;
    }
  }

  &__pricing {
    height: 35px;
  }

  &__error {
    margin-left: 15px;
    margin-bottom: 10px;
    position: relative;
    padding: 30px;

    @include breakpoints-down(sm) {
      margin: 0px 10px 10px;
      max-width: calc(100vw - 30px);
    }

    &--close {
      position: absolute;
      top: 2px;
      right: 2px;

      @include breakpoints-down(sm) {
        top: -6px;
        right: -20px;
      }
    }
  }

  .PlayIcon svg,
  .PauseIcon svg {
    fill: #84a3ba;
  }

  .MessagesAudioPlayer__progress-bar-fill {
    background-color: #84a3ba;
  }
}

//MC Tips and Gifts
.MessageTip,
.MessageGift {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
  }

  svg {
    width: 16px !important;
    height: 16px !important;
    margin-right: 5px;
  }

  img {
    position: relative;
    right: 5px;
  }
}

.MessageTip {
  &__amount {
    border-radius: 3px;
    min-width: 80px;
    height: 40px;
    width: 100%;
    min-width: 115px;
    color: #ffffff;
    background: #ee941b;
  }
}

.MediaCarouselModal {
  height: 100dvh;
  display: flex;
  background-color: rgba(
    var(--COLOR_POPOVER_BACKGROUND, 0, 0, 0),
    0.76
  ) !important;

  &__container {
    width: 100vw;
    background-color: #ffffff;
  }

  &__header {
    width: 100%;
    height: auto;
    background: var(--highlight-color, #d8e8ef);
    padding: 0px 25px;

    .MessageCenterModelNameBar__name {
      min-width: 100px;
    }

    @include breakpoints-down(sm) {
      padding: 8px 16px;
    }
  }

  &__media {
    height: 50vh;

    &-selected-image {
      .MessageCenterUnavailableAttachment {
        border: unset;

        &.w-full {
          width: 100vw !important;
        }

        .MessageCenterUnavailableAttachment__text {
          max-width: 150px;
        }
      }
    }

    &-selected-image,
    .react-swipeable-view-container > div {
      width: 100%;
      height: 100%;
      border-radius: unset;
      max-height: unset;
      max-width: unset;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img,
    video {
      object-fit: contain;
      margin: 0 auto;
      height: 100% !important;
      border-radius: unset;
      width: auto !important;
      max-height: calc(100vh - 100px);
      max-width: 100vw;
      min-width: 140px;
    }

    .Avatar,
    .AvatarPing {
      width: 200px !important;
      height: 200px !important;

      @include breakpoints-down(sm) {
        width: 140px !important;
        height: 140px !important;
      }

      .initial-avatar {
        font-size: 60px;
      }
    }

    .AvatarPing {
      background: var(--avatar-primary-bg-color, #84a3ba);
    }

    .AudioPlayer__container {
      position: absolute;
      padding: 10px;
      bottom: 30px;
      width: calc(100vw - 50px);
      height: 54px !important;
      border: 1px solid #ebebeb;
      border-radius: 10px;

      svg {
        fill: #84a3ba;
      }
    }
  }

  &__footer {
    width: 100%;
    height: auto;
    background: var(--highlight-color, #d8e8ef);
    padding: 0 25px;

    .selectedIcon {
      border: 3px solid var(--pink-color, #f472b7);
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .carouselIconBox {
      position: relative;
      padding: 15px 5px;
      aspect-ratio: 1;
      object-fit: cover;

      .carouselIcon {
        max-width: unset !important;
        max-height: unset !important;
        aspect-ratio: 1 !important;
        object-fit: cover !important;
        height: 115px !important;
        width: 115px !important;

        @include breakpoints-down(sm) {
          height: 40px !important;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (orientation: portrait) {
            width: 100% !important;
          }
        }
      }

      .PlayIcon {
        opacity: 0.8;
      }

      .carouselPlayButton {
        position: absolute;
        bottom: 50px;
        left: 50px;
        color: white;

        @include breakpoints-down(sm) {
          bottom: 25px;
          left: 18px;
        }
      }

      @include breakpoints-down(sm) {
        .CustomIcon svg {
          fill: currentColor;
          width: 10px;
          height: 10px;
        }
      }
    }

    .Avatar {
      width: 100%;
      height: 100%;
      border-radius: unset;

      .initial-avatar {
        font-size: 60px;

        @include breakpoints-down(sm) {
          font-size: 20px;
        }
      }
    }

    .MessageMediaCarouselImage {
      .MessageCenterUnavailableAttachment {
        border: unset;

        @include breakpoints-down(sm) {
          padding: unset;
        }

        svg {
          width: 70%;
          height: auto;
        }

        &__text {
          display: none;
        }
      }
    }
  }
}

.MessageCenterUnavailableAttachment {
  border: 2px solid rgba(233, 238, 242, 0.7);
  padding: 10px;

  svg {
    height: auto;
  }
}

@keyframes WaveFormQuiet {
  25% {
    transform: scaleY(0.3);
  }

  50% {
    transform: scaleY(0.1);
  }

  75% {
    transform: scaleY(0.3);
  }
}

@keyframes WaveFormNormal {
  25% {
    transform: scaleY(0.6);
  }

  50% {
    transform: scaleY(0.1);
  }

  75% {
    transform: scaleY(0.6);
  }
}

@keyframes WaveFormLoud {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.1);
  }

  75% {
    transform: scaleY(1);
  }
}

.MessageNavigatorConversationPreview {
  position: relative;
  $font-size: 13px;
  $line-height: 1.43;
  font-size: $font-size;
  line-height: $line-height;
  display: flex;

  span {
    position: relative;
    @include multilineEllipsis(1, 100%, $font-size, $line-height);
  }

  .TokenIcon,
  .GiftIcon2 {
    padding-right: 22px;

    svg {
      position: absolute;
      top: -3px;
      left: 0;
      width: 16px;
      height: 22px;
    }
  }
}
