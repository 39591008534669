// Small tablets and large smartphones (landscape view)
$screen-xs-max: 750px;

// Small tablets (portrait view)
$screen-sm-max: 960px;

// Tablets and small desktops
$screen-md-max: 1280px;

// Large tablets and desktops
$screen-lg-max: 1920px;

$screen-xl-max: 2560px;
$screen-widescreen-max: 3840px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$screen-md-max}) {
    @content;
  }
}
@mixin desktop-lg {
  @media (min-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 70em) {
      @content;
    }
  } @else if $point == laptop {
    @media (min-width: 64em) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 50em) {
      @content;
    }
  } @else if $point == phablet {
    @media (min-width: 37.5em) {
      @content;
    }
  } @else if $point == mobileonly {
    @media (max-width: 37.5em) {
      @content;
    }
  }
}

$breakpoints: (
        xs: $screen-xs-max,
        sm: $screen-sm-max,
        md: $screen-md-max,
        lg: $screen-lg-max,
        xl: $screen-xl-max,
        widescreen: $screen-widescreen-max
);

@mixin breakpoints-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin breakpoints-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin breakpoints-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

$header-height: 100px;