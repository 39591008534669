@import "color";
$card-border-radius: 20px;
$auth-modal-form-width: 550px;
$button-vertical-padding:10px;
$input-padding:12px 20px;
$grid-columns: 12;
$grid-gutter-width: 20px;
$table-border-radius: 20px;

// 20px for contests-b
$contest-content-space: 8%;

$container-max-widths: (xs:'sm',
  sm:'md',
  md:'lg',
  lg:'xl',
);

$container-max-widths: (sm: 560px,
  md: 720px,
  lg: 1200px,
  xl: 1600px,
);

$xs-breakpoint: 0px;
$sm-breakpoint: 600px;
$md-breakpoint: 960px;
$lg-breakpoint: 1200px;
$xl-breakpoint:1920px;

$responsive-breakpoints: (xs: $xs-breakpoint,
  sm: $sm-breakpoint,
  md: $md-breakpoint,
  lg: $lg-breakpoint,
  xl: $xl-breakpoint,
);
$grid-breakpoints: $responsive-breakpoints;
$responsive-breakpoint-end: (xs: $sm-breakpoint,
  sm: $md-breakpoint,
  md: $lg-breakpoint,
  lg: $xl-breakpoint,
  xl: 100000px,
);

$font-family: 'Lato',
-apple-system,
BlinkMacSystemFont,
'Segoe UI',
'Roboto',
'Oxygen',
'Ubuntu',
'Cantarell',
'Fira Sans',
'Droid Sans',
'Helvetica Neue',
sans-serif !important;

$font-anton: 'Anton',
-apple-system,
BlinkMacSystemFont,
'Segoe UI',
'Roboto',
'Oxygen',
'Ubuntu',
'Cantarell',
'Fira Sans',
'Droid Sans',
'Helvetica Neue',
sans-serif !important;

$auth-modal-gradient-background: linear-gradient(150deg,
  rgba($orange-1, 0.9) 1%,
  rgba($blue-2, 0.9) 18%,
  rgba($blue-2, 0.9) 52%,
  rgba($blue-2, 0.9) 83%,
  rgba($red-3, 0.9) 100%);