 .ReactCrop__crop-selection {
   &::after {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     background: radial-gradient(circle, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6908964269301471) 50%, rgba(0, 0, 0, 0.9317927854735644) 100%);
     width: 100%;
     height: 100%;
   }
 }

 .MyPage {
   display: flex;
   flex-direction: column;
   min-height: 100%;
   color: var(--title-color-1);



   &__Card {
     border-radius: 3px !important;
   }

   &ProfileBio {
     min-height: 250px;

     &__live-button {
       position: absolute !important;
       right: 25px;
       top: 5px;
     }

     &__dropdown,
     &__textfield {
       .MuiFormHelperText-root {
         color: var(--pink-color);
       }
     }

     &__Button {
       display: flex;
       justify-content: flex-end;
     }

     &__photo-helper {
       margin-top: 0.5rem;
       color: var(--pink-color);
     }
   }

   &_my_bio_field {
     textarea {
       color: var(--text-main-color) !important;

       &:disabled {
         color: black;
       }
     }
   }
 }