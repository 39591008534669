.ReactModalPortal {
  position: relative;
  z-index: 999;

  .ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  * {
    outline: none !important;
    outline-color: transparent;
  }
}
