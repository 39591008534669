@import "../../library/src/styles/color";
@import "../../library/src/styles/utility";

.UpdateDocuments {
  display: flex;
  justify-content: center;
  padding: 16px 0 0 0 !important;
  overflow: auto;
  @include scrollbarStyle();
  .MuiCircularProgress-root {
    margin-top: 5em;
  }
}

.UpdateDocuments__document-title--active {
  color: $orange-5 !important;
}
