@import "../../../../library/src/styles/responsive";
@import "../../../../library/src/styles/dimensions";
@import "../../../../library/src/styles/utility";
@import "../../../../library/src/styles/color";

.DashboardHeader {
  $this: &;
  min-height: var(--header-height);
  z-index: 10;
  width: 100%;
  min-width: 100%;
  display: flex;

  @include transition(padding-left 0.3s ease);

  &--litemode {
    display: none;
  }

  &--mobile:not(#{$this}--menu-open) {
    #{$this}__bg {
      border-top-left-radius: var(--sidebar-border-radius);
    }
  }

  &__welcome {
    display: flex;
    align-items: center;
  }

  &__bg {
    border-top-left-radius: var(--sidebar-border-radius);
    border-top-right-radius: var(--sidebar-border-radius);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--header-bg-color);
    color: var(--header-text-color) !important;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__star-ratings-container,
  &__greeting {
    display: flex;
    align-items: center;
    margin-left: 15px;
    color: var(--header-text-color);
  }

  &__username {
    font-size: 1.5rem;
  }

  &__star-ratings {
    font-size: 1.25rem !important;

    &-container {
      cursor: pointer;
    }

    &-average {
      margin-left: 10px;
      font-size: 0.75rem;
    }

    &-popover {
      padding: 1rem 2rem !important;
      overflow: hidden !important;
    }

    &-rating-bar {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .MuiLinearProgress-root {
        margin: 0 10px;
        width: 150px;
        height: 20px;
        background-color: $blue-18 !important;
      }
      .MuiLinearProgress-bar {
        background-color: $orange-1;
      }
    }

    .MuiRating-icon,
    .MuiRating-iconEmpty {
      color: $orange-1;
    }
  }

  &__right {
    margin: 0 20px 0 0;
  }

  &__go-live-button {
    display: flex;
    align-items: center;
    color: var(--header-text-color);
    .material-icons {
      font-size: 40px;
    }
  }

  &__earnings-page-button {
    color: var(--header-text-color);
  }

  &__notifications-icon,
  &__messages-icon {
    color: var(--header-text-color);
  }
  &__notifications-badge {
    .MuiBadge-badge {
      background-color: $pink-2;
      color: $white;
    }
  }

  &__messages-icon {
    color: var(--header-text-color);
  }
  &__messages-badge {
    .MuiBadge-badge {
      border: 2px solid;
      color: var(--secondary-bg-color);
      background-color: var(--strong-color);
      border-color: var(--secondary-bg-color);
    }
  }

  &--mobile {
    min-height: 60px;
    border-bottom: 1px solid #999;
    box-shadow: 0px 16px 10px -20px #111;
    .main-logo {
      max-height: 50px;
    }
    .DashboardHeader__right {
      margin: 0 5px 0 0;
    }
  }
}

.NavigationIconButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__label {
    color: var(--header-text-color);
  }
}

.notificationMenu {
  overflow-x: unset !important;
  overflow-y: unset !important;
  margin-top: 1rem;
  .MuiMenu-list {
    padding-right: 0 !important;
    width: 100% !important;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 82px;
    margin: auto;
    z-index: 1000;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: rgba(var(--primary-bg-color-rgb), 0.9) transparent;
    top: -12px;
  }
}

.NavigationMessagesBox {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--desktop {
    margin-left: 1rem;
    margin-right: 2.5rem;
  }

  &--mobile {
    margin-right: 1rem;
  }

  &__badge {
    .MuiBadge-badge {
      background-color: #ffffff !important;
      color: #000000;
    }
  }

  &__loading {
    color: #ffffff !important;
  }

  &__icon-button {
    padding: 0 !important;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }

  &__menu {
    width: 30vw;

    @include breakpoints-down(xs) {
      width: 90vw;
    }

    @include breakpoints-between(xs, sm) {
      width: 90vw;
    }

    @include breakpoints-between(sm, md) {
      width: 40vw;
    }

    @include breakpoints-between(md, lg) {
      width: 30vw;
    }

    @include breakpoints-between(lg, xl) {
      width: 20vw;
    }

    background-color: rgba(#1e1f47, 0.9) !important;
    border: 1px solid #2e2b66 !important;
    color: #ffffff !important;
  }
}
