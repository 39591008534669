.BroadcastAgreement {
  width: 100%;
  max-width: 1000px;
  background: white;
  padding: 50px;
  justify-self: flex-start;
  min-height: calc(100vh - var(--header-height));
  overflow-x: hidden;

  &__center_h2 {
    font-size: 14px;
    font-weight: bold;
    padding-top: 6px;
    margin: 0;
  }

  &__hr {
    border-top: 1px solid #ccc;
    display: block;
    overflow: hidden;
    height: 1px;
    font-size: 1px;
    margin-top: 6px;
  }

  table {
    border-spacing: 0;
    border: 1px solid #ccc;
    td,
    tr,
    th {
      padding: 20px;
      border: 1px solid #ccc;
    }
  }

  &__content1 {
    font-size: 12px;
    padding: 6px 0;
  }

  &__keys {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
  }

  ol,ul {
    list-style: disc;
    li {
      margin-left: 1.5rem;
    }
  }

  h1,
  h2,
  h4,
  h3{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
  }

  a {
    text-decoration: underline;
    color: #ef951b;
    cursor: pointer;

    &:hover {
      color: #ef951b;
    }
  }

  p {
    margin-bottom: 0.5rem;
  }

  .p-title {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
}
