@import "../../../library/src/styles/responsive";
@import "../../../library/src/styles/color";

.SharedMediaModal {
  min-width: 665px;
  max-width: 700px;
  font-size: 12px;
  height: 80vh;
  max-height: 680px;
  width: 50vw !important;

  .MCSharedMediaModalGrid__date span {
    font-size: 12px;
  }

  @include breakpoints-down(sm) {
    width: 100vw !important;
    height: 100vh !important;
    height: 100dvh !important;
    max-height: unset !important;
    min-width: 100vw;
  }

  img {
    aspect-ratio: 1;

    @include breakpoints-down(sm) {
      max-width: unset !important;
    }
  }

  .img-container {
    border: 1px solid var(--quaternary-bg-color);
    aspect-ratio: 1;

    .error-image {
      padding: 8px;
    }
  }

  .SharedMediaModal__tab-bar {
    svg {
      height: 16px;
      width: 16px;
    }
  }
}
