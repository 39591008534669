.BroadcastC2CList {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.BroadcastC2CViewer {
    background-color: black;
    width: 165px;
    height: 120px;
    position: relative;

    &__info {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 20px;
        background-color: rgba(#0b0b17, 0.2);
        color: white;
        padding: 4px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__flex {
        flex-grow: 1;
    }

    &__icon {
        font-size: 16px !important;
        margin: 0 2px;
        color: white;
        cursor: pointer;
    }
}

.BroadcastC2CRTCViewer {
    background-color: black;
    width: 165px;
    position: relative;
    -webkit-transition: visibility 500ms; 
    -moz-transition: visibility 500ms; 
    -ms-transition: visibility 500ms; 
    -o-transition: visibility 500ms; 
    transition: visibility 500ms;  

    &__info {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 20px;
        background-color: rgba(#0b0b17, 0.2);
        color: white;
        padding: 4px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__flex {
        flex-grow: 1;
    }

    &__icon {
        font-size: 16px !important;
        margin: 0 2px;
        color: white;
        cursor: pointer;
    }
}