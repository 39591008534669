@import "../../../library/src/styles/color";

.BroadcastViewers {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;

  &__tab {
    height: 26px;
    color: var(--title-color-1);
    border: 1px solid var(--title-color-1);
    background-color: var(--secondary-bg-color);
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
    padding: 6px 13px;

    &.selected {
      font-weight: bold;
      color: var(--link-hover-active-color);
    }
  }

  &__member-badge {
    min-width: 14px;
    width: 14px;
    height: 14px;
    margin: 0 5px;

    svg {
      width: 20px;
    }

    &--1 {
      svg {
        fill: #f03c32;
      }
    }

    &--2 {
      svg {
        fill: #ef951b;
      }
    }
  }

  &__member-medal {
    width: 14px !important;
    height: 14px;
    position: relative;
    border-radius: 50%;
    margin-right: 5px;
    background-color: $yellow-1;

    &::before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--primary-bg-color);
      content: "";
    }

    &::after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 1px;
      border-radius: 50%;
      background-color: $yellow-1;
      content: "";
    }
  }

  &__member-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 13px;
    position: relative;
    overflow: hidden;
  }

  &__member-name {
    color: var(--title-color-1);
    margin-left: 3px;
  }

  &__member-spacing {
    flex-grow: 1;
  }

  &__member-more {
    padding: 0 !important;

    &--open {
      position: relative;
      z-index: 10;
      background-color: var(--primary-bg-color);
    }

    & .material-icons {
      color: var(--title-color-1);
    }
  }

  &__member-list {
    overflow: auto;
    height: fit-content;
    color: var(--title-color-1);
  }

  &__member-menu {
    display: none;
    position: relative;
    width: 100%;
    background-color: var(--primary-bg-color);
    transition: right 0.1s linear;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--open {
      display: block;
    }

    &-item {
      flex-grow: 1;
      border-right: 1px solid var(--title-color-1);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      &:hover {
        background: var(--link-active-hover-color);
      }

      &-mute {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  &__voyeur-list {
    margin-top: 20px;

    &-title {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &__muted {
    margin-left: 10px;
    font-size: 12px;
  }

  &__member-box {
    position: absolute;
    bottom: 0;
  }

  &__member-item.whisperedActive {
    border: 1px solid #db2777;
  }
}
