@import '../../../../library/src/styles/color';

.BroadcastMemberBox {
  width: 100%;
  min-height: 150px;
  max-height: 310px;
  background: var(--primary-bg-color);
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

  &__info_container {
    position: relative;
    padding-bottom: 23px;
    border-bottom: 1px solid var(--primary-bg-color);
    display: flex;
    justify-content: flex-start;
  }

  .BroadcastMemberBox__info_container p {
    color: var(--title-color-1);
    margin: 0;
    font-size: 13px;
  }

  p.BroadcastMemberBox__info_balance {
    color: var(--title-color-1);
    margin: 8px 0;
    font-size: 15px;
  }

  &__info_rank {
    width: 14px;
    height: 14px;
    margin-right: 7px;

    &--1 {
      svg {
        fill: #f03c32;
      }
    }

    &--2 {
      svg {
        fill: #ef951b;
      }
    }
  }

  &__info_text {
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    color: var(--title-color-1);
    word-break: break-word;

    > div {
      display: flex;
      align-items: center;
      img {
        width: 17px;
        height: 17px;
        margin-right: 3px;
      }
    }
  }

  &__info_close {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  &__info_balance {
    font-size: 14px;
  }

  &__comments {
    overflow-y: scroll;
    flex-grow: 1;
    margin-bottom: 10px;
  }

  &__comment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px;
    border-bottom: 1px solid var(--title-color-1);
  }

  &__comment-content {
    color: var(--title-color-1);
    max-width: 60%;
    word-break: break-all;
  }

  &__comment-date {
    color: rgba(var(--title-color-1-rgb), 0.6);
  }

  &__input {
    border: none;
    background: transparent;
    color: $white;
    flex-grow: 1;
    font-size: 12px;

    &::placeholder {
      color: rgba(var(--title-color-1-rgb), 0.2);
    }

    &:focus {
      outline: none;
    }
  }
}
