@import "../../styles/_color";
@import "../../styles/_dimensions";

.Swipeable-slider {
  position: relative;
  overflow: hidden;

  .react-swipeable-view-container {
    width: 100%;
    height: 100%;
  }

  &__arrow {
    color: rgba(140, 148, 193,0.5);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: var(--card-border-radius);

    &-left,
    &-right {
      position: absolute;
      top: 50%;
      z-index: 10;
      line-height: 40px;
      text-align: center;
      height: 40px;
      width: auto;
      transform: translateY(-50%);
      transition: 0.5s;
      color: white;
      cursor: pointer;
      > svg {
        color: #fff;
        transition: visibility 0s linear 0.3s, opacity 0.3s linear 0s;
        &:hover{
          color: #337ab7;
          transform: scale(1.1);
          transition: visibility 0s linear 0.3s, opacity 0.3s linear 0s;
        }
      }

      &--hidden {
        visibility: hidden;
      }
    }

    &--large {
      svg {
        height: 120px;
      }
    }

    &--medium {
      svg {
        height: 90px;
      }
    }

    &--small {
      svg {
        height: 60px;
      }
    }

    &--extra-small {
      svg {
        height: 30px;
      }
    }

    $arrow-margin: 10px;

    &-left {
      left: $arrow-margin;
    }

    &-right {
      right: $arrow-margin;
    }

  }
}
