@import "../../../library/src/styles/color";
.BroadcastControl {
  flex: none !important;
  margin-bottom: 12px !important;
  padding: 0 !important;

  &__Title {
    margin-bottom: 10px;
  }

  &__card {
    padding: 0 !important;
    height: auto;
  }

  &__icon {
    opacity: 0.7;
    svg {
      width: 35px;
      height: 35px;
    }

    &--selected {
      opacity: 1;
    }
  }
  &__wrapper {

    & .TabGroup {
      display: flex;
      flex-direction: column;
      width: 100%;
      &__content {
        display: none;
      }
      &__Tabs {
        .MuiTabs-flexContainer {
          height: 100%;
        }
        div {
          div {
            border: none;
            justify-content: space-around;
            button {
              background-color: var(--card-primary-bg-color) !important;
              margin-right: 0px !important;
              padding: 6px 0px !important;
              .RadioLabel {
                color: black !important;
                font-size: 1em;
                text-transform: none;
              }
            }

            .Mui-selected {
              background-color: white !important;
            }
            button:disabled {
              cursor: not-allowed !important;
              pointer-events: unset !important;
              .RadioLabel {
                color: $gray-14 !important;
              }
            }
          }
        }
      }

      & .TabGroup__content {
        flex-grow: 1;
        height: calc(100% - 200px);
      }

      .MuiTab-root {
        padding-left: 6px;
        padding-right: 6px;
        overflow: initial;
      }

      .MuiTabs-indicator {
        bottom: 5px !important;
      }
    }
  }
  &__Panels {
    width: 100%;
    height: 100%;

    .MuiBox-root,
    .MuiBox-root {
      padding: 0px;
    }

    & > div {
      height: 100%;
    }

    div {
      div {
        // padding: 0px 6px;
        .MuiCollapse {
          &-wrapper {
            padding: 0px;
          }
          &-wrapperInner {
            padding: 0px;
          }
        }
      }

      .no-padding {
        div {
          padding: inherit;
        }
      }
    }
  }
  &__ShowType,
  &__GameSettings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    &-Radio {
      width: 100%;
      border-bottom: 1px solid var(--title-color-1);
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 30px;
    }

    &-Detail {
      text-align: center;
      color: var(--title-color-1);
      font-size: 12px;
      min-height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-Curtain,
    &-Nude {
      border: 1px solid var(--title-color-1);
      background-color: var(--primary-bg-color);
      color: var(--title-color-1);
      font-size: 12px;
      text-align: center;
      height: 21px;
      min-width: 80px;

      &-Disabled {
        filter: grayscale(1);
        pointer-events: none;
        user-select: none;
      }
    }

    &-Warning {
      color: $red-10;
      margin-bottom: 12px;
    }
  }
  &__GameSettings {
    &-Wheel {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-height: 30px;
      border-bottom: 0;

      > div {
        margin: 0 auto;

        &.disabled-option {
          filter: opacity(0.5);
        }
      }
    }

    > div:last-child {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;
      }
    }
  }
  &__buttonSection {
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: center;
    width: 80%;
    padding-top: 32px;
    &__button {
      font-size: 1em !important;
      text-transform: none !important;
      width: 120px !important;
      padding: 5px 0px !important;

      &__selected {
        font-size: 1em !important;
        text-transform: none !important;
        color: var(--link-hover-active-color) !important;
      }
    }
  }
}
