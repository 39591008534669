@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--title-color-1) !important;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: unset !important;
}

// input:-internal-autofill-selected{
//   background-color: inherit !important;
// }

.normalize-button {
  all: unset
}

.normalize-html {
  h1, h2, h3, h4, h5, h6, p, a {
    font-size: revert !important;
  }
}

.prettify-html {
  p,
  ol,
  ul,
  pre,
  li {
    margin-bottom: 0.5rem;
  }

  a {
    text-decoration: underline;
  }
}