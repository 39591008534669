@import "../../../styles/color";
@import "../../../styles/responsive";

// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
// the captcha either needs the badge or a text with accompanying links to Google's terms of use and privacy policy
// the recaptcha is hidden on both login and signup modals hence the badge is hidden too
// pre request of product owner, instead of the text there is a custom design with badge
// that links to Google's terms of use and privacy policy
.grecaptcha-badge {
  visibility: hidden;
}

// on extra small devices, scroll sometimes does not work because of captcha placement
@include sm {
  iframe[title*="recaptcha challenge"] {
    transform: scale(0.75);
    transform-origin: 0 0 0;
  }
}

.Recaptcha {
  > *,
  &__links > a {
    color: var(--link-color);
  }

  &__wrapper {
    width: 280px;
  }

  &__links > a:last-child {
    margin-left: 0.5rem;
  }

  &__logo {
    > img {
      width: 40px;
      height: 40px;
    }
  }

  & > a:hover {
    color: var(--link-hover-active-color);
  }
}
