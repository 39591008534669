@import "../../library/src/styles/color";
@import "../../library/src/styles/dimensions";

.AccountSuspendedDisclaimer {
  padding: 2rem;
  width: 100%;
  max-width: $auth-modal-form-width;
  background: var(--primary-bg-color);
  color: var(--title-color-1);
}
