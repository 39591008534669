@import "../../../../library/src/styles/responsive";
@import "../../../../library/src/styles/dimensions";
@import "../../../../library/src/styles/utility";
@import "../../../../library/src/styles/color";

.DashboardSidebar {
  $this: &;
  background-color: var(--sidebar-bg-color);
  color: var(--sidebar-text-color) !important;
  @include transition(width 0.3s ease);

  z-index: 10;
  border-bottom-left-radius: var(--sidebar-border-radius);
  border-right: 5px solid var(--sidebar-border-right-color);
  display: flex;
  width: var(--sidebar-width);
  flex-direction: column;

  &--litemode {
    display: none;
  }

  &--mobile {
    position: absolute;
    left: 0;
    z-index: 90;
    height: 100%;

    &:not(#{$this}--open) {
      width: 0;
      margin-left: -5px;
    }
  }

  &:not(#{$this}--mobile):not(#{$this}--open) {
    width: 72px;
    margin-left: 0;
  }

  &--incomplete {
    z-index: 20;
    border-radius: var(--sidebar-border-radius);
    width: var(--sidebar-width);
    background-color: var(--sidebar-bg-color);
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 22px;
    margin-top: 30px;
    @include transition(width 0.3s ease);
  }

  &__streamray-logo {
    height: calc(var(--header-height) - 10px);
    width: auto;
    fill: var(--logo-color);
    max-width: 220px;
  }

  &__cams-logo {
    width: 64px;
    height: auto;
    fill: var(--logo-color);
  }

  &__navigation {
    padding-top: 22px;
    width: var(--sidebar-width);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;

    & > ul:first-child {
      flex: 1;
    }

    @include scrollbarStyle();
  }
  &__navigation-seperator {
    border: none;
    border-top: 1px solid var(--faded-color);
    width: 100%;
    opacity: 0.2;
  }

  &__menu-toggle {
    color: var(--header-text-color) !important;
    &:hover {
      color: var(--link-active-hover-color) !important;
    }
  }
}
