@import "../../styles/_color";

.GenericFileUploadDropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  overflow: hidden;

  $min-height: 148px;

  $this: &;

  &--shape {
    &-rectangle {
      border-radius: 3px;
    }

    &-soft-rectangle {
      border-radius: var(--media-border-radius);
    }

    &-rectangle,
    &-soft-rectangle {
      min-height: $min-height;
      #{$this}__prompt{
        min-height: $min-height;
      }
    }


    &-circle {
      border-radius: 50% !important;
      height: 100px;
      width: 100px;
    }
  }

  &:focus {
    outline: none !important;
  }

  &__active {
    border-style: dashed !important;
  }

  &__accept {
    border-color: var(--green-color);
    color: var(--green-color);
  }

  &__reject {
    border-color: var(--red-color);
    color: var(--red-color);
  }

  &__prompt {
    font-size: 1.125rem;
    text-align: center;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      max-width: 100%;
    }
  }

  &__filename {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: var(--primary-bg-color);
  }

  &__icon {
    font-size: 3rem !important;

    &-wrapper {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
}
