.TailwindSwitch {
  $this: &;
  $circle: #{$this}__circle;

  &--size {
    &-xs {
      #{$circle} {
        right: calc(1.75rem + 4px);

        &--checked {
          right: 4px;
        }
      }
    }

    &-xs-wide {
      #{$circle} {
        right: calc(3rem + 7px);

        &--checked {
          right: 6px;
        }
      }
    }

    &-sm {
      #{$circle} {
        right: calc(1.25rem);

        &--checked {
          right: 4px;
        }
      }

      #{$circle}--has-label {
        right: calc(1.75rem + 8px);
      }
      #{$circle}--has-label#{$circle}--checked {
        right: 4px;
      }
    }

    &-normal {
      #{$circle} {
        right: calc(1.75rem);

        &--checked {
          right: 4px;
        }
      }

      #{$circle}--has-label {
        right: calc(1.75rem + 8px);
      }
      #{$circle}--has-label#{$circle}--checked {
        right: 4px;
      }
    }
  }
}