@import "../../../library/src/styles/color";

.BroadcastShowTypeModal {
  padding: 30px 40px;
  position: relative;
  min-height: 200px;
  border-radius: 20px;
  color: $black;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &__text {
    color: $black;
    font-size: 20px;
  }

  >div {
    margin-bottom: 50px;

    &.agree-or-cancel-tipping {
      margin-bottom: 0;
    }
  }

  .agree-or-cancel-tipping {
    display: flex;
    justify-content: center;

    .confirmation-cancel {
      width: 150px;
      margin-left: 20px;
    }
  }
}