@import "dimensions";

html,
body {

  // order of these are important, it should be large to small to override each other
  // the values here have been fine tuned. they look arbitrary and inconsistent however
  // when you open chrome dev tools, the goal is to check the Computed tab for font-size
  // and verify that it is around 16 pixels (1rem). the goal is to resize fonts based on the width of the device
  // so text does not wrap onto new line OR prevent button text that shrinks/expands and
  // causes width/height increase on the button itself. 1rem is ideal to human eyes
  // rems based on the font sizes on html and body makes a sum percentage that we can only verify by
  // checking the computed value. the values here should increment every
  @screen 2xl {
    font-size: .825vw !important;
  }

  @screen 1xl {
    font-size: 1.050vw !important;
  }

  @screen xl {
    font-size: 1.25vw !important;
  }

  @screen lg {
    font-size: 1.5vw !important;
  }

  @screen md {
    font-size: 2vw !important;
  }

  @screen sm {
    font-size: 2.5vw !important;
  }

  @screen xs {
    font-size: 3.5vw !important;
  }

  font-family: $font-family;
  font-weight: normal !important;
}

*:not(.material-icons) {
  font-family: $font-family;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
strong,
b,
i,
a {
  font-family: $font-family;
  color: var(--text-main-color);
}

.LandingCover__title {
  color: black !important;
}

a,
.link {
  border: none;
  background: none;
  padding-left: 0;
  padding-right: 0;

  &,
  &:hover {
    text-decoration: none;
  }

  &.large {
    font-size: 1rem;
  }

  &.orange,
  &.white,
  &.coral {
    &:hover {
      text-decoration: underline !important;
    }
  }

  &.coral {
    color: $orange-5;

    &:hover {
      color: $orange-5;
    }
  }
}

ul,
ol {
  li {
    font-size: 1rem;
    line-height: 1.2rem;
    color: var(--text-main-color) !important;

    &::marker {
      color: var(--text-main-color) !important;
    }

  }
}

h1,
.h1 {
  font-size: 6rem;

  small {
    font-size: 4.5rem;
  }

  &.half {
    font-size: 5.5rem;

    small {
      font-size: 4.125rem;
    }
  }
}

h2,
.h2 {
  font-size: 5rem;

  small {
    font-size: 3.75rem;
  }

  &.half {
    font-size: 4.5rem;

    small {
      font-size: 3.375rem;
    }
  }
}

h3,
.h3 {
  font-size: 4rem;

  small {
    font-size: 3rem;
  }

  &.half {
    font-size: 3.5rem;

    small {
      font-size: 2.625rem;
    }
  }
}

h4,
.h4 {
  font-size: 2.5rem;

  small {
    font-size: 2rem;
  }

  &.half {
    font-size: 1.25rem;

    small {
      font-size: 1rem;
    }
  }
}

h5,
.h5 {
  font-size: 1.5rem;

  small {
    font-size: 1.50rem;
  }

  &.half {
    font-size: .75rem;

    small {
      font-size: .50rem;
    }
  }
}

h6,
.h6 {
  font-size: 1rem;

  small {
    font-size: 0.75rem;
  }
}

p {

  &,
  a {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: normal;
  }

  a {

    &:hover {
      text-decoration: underline;
    }
  }
}

.subTitle-1 {
  font-size: .9rem;
}

.subTitle-2 {
  font-size: .8rem;
}

small,
.captionText {
  font-size: .7rem;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5 {
  margin: 0;
}

.display-5 {
  font-size: 3rem;
}

.display-4 {
  font-size: 2.5rem;
}

.display-3 {
  font-size: 2rem;
}

.display-2 {
  font-size: 1.5rem;
}

.display-1 {
  font-size: 1rem;
}

.body-1 {

  &,
  a {
    font-size: 1rem;
    line-height: 1rem;
  }

  margin-bottom: .4rem;
}

.body-2 {

  &,
  a {
    font-size: .6rem;
    line-height: .8rem;
  }

  margin-bottom: .3rem;
}

.tab-text {
  padding: 6px 12px;
  font-size: 1rem;
}

/*---------COLORS--------------*/

.colorPrimary {
  color: var(--title-color-1) !important;
}

.colorSecondary {
  color: var(--title-color-2) !important;
}

.colorTertiary {
  color: var(--title-color-1) !important;
}

.colorQuaternary {
  color: var(--primary-bg-color) !important;
}

.colorTitle {
  color: var(--title-color-5) !important;
}

.colorStrong {
  color: var(--strong-color) !important;
}

.colorMuted {
  color: $gray-3 !important;
}

.colorError {
  color: var(--error-red) !important;
}

.colorWarning,
.colorLink-1 {
  color: var(--link-color) !important;
}

.colorLink-2 {
  color: $orange-5 !important;
}

.colorSuccess {
  color: var(--green-color) !important;
}

/*--------------------------------*/

/*---------MODIFIERS--------------*/

.textLight {
  font-weight: 300;
}

.textBold {
  font-weight: bold;
}

.textItalic {
  font-style: italic;
}

.textCenter {
  text-align: center;
}

.textCapitalize {
  text-transform: capitalize;
}

ul.simpleList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.fontAnton {
  font-family: $font-anton;
}

.text-24 {
  font-size: 1.5rem;
}

.text-20 {
  font-size: 1.25rem;
}

.text-18 {
  font-size: 1.125rem;
}

.text-16 {
  font-size: 1rem;
}

.text-14 {
  font-size: 0.875rem;
}

.text-12 {
  font-size: 0.75rem;
}

/*--------------------------------*/