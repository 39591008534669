@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "/node_modules/flag-icons/css/flag-icons.min.css";
@import "../library/src/styles/common"; //order of this should be last

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  & > .svg-inline--fa {
    font-size: 12px;
  }
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--title-color-1) !important;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: unset !important;
}

// input:-internal-autofill-selected{
//   background-color: inherit !important;
// }

.normalize-button {
  all: unset;
}

pre {
  white-space: normal;
}

.bg-transparent--i {
  background-color: transparent !important;
}
.p-0--i {
  padding: 0 !important;
}
