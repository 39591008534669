.BroadcastMemberVerifyViewer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .BroadcastC2CRTCViewer {
    display: flex;
    background-color: black;
    width: 100%;
    position: relative;
    -webkit-transition: visibility 500ms;
    -moz-transition: visibility 500ms;
    -ms-transition: visibility 500ms;
    -o-transition: visibility 500ms;
    transition: visibility 500ms;
    overflow: hidden;

    &__info {
      display: none !important;
    }
  }
}
