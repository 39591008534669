@import '../../../styles/color';

.HelpTooltip {
  &__icon {
    color: $gray-11 !important;
    border-radius: 100%;
    line-height: 210%;
    vertical-align: middle;
    opacity: 0.6 !important;
    transition: 0.3s !important;

    &:hover {
      color: $gray-6 !important;
      opacity: 1 !important;
    }

    &--small {
      font-size: 1rem !important;
    }
  }
}
