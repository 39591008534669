@import "../../../../library/src/styles/dimensions";
@import "../../../../library/src/styles/utility";
@import "../../../../library/src/styles/responsive";

.ContestCard{
  position: relative;
  display: flex;
  flex-direction: column;
  .Card__content{
    width: 100%;
  }
  &__ribbon{
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;
  }

  &__cover{
    position: relative;
    z-index: 1;
    margin-bottom: -12%;
    img{
      width:100%;
      height: auto;
      max-height: 640px;
      object-fit: cover;
    }

    &:after{
      display: block;
      content: "";
      width: 100%;
      height: 30%;
      position: absolute;
      bottom: 0;
      $color: var(--secondary-bg-color);
      @include linear-gradient(to bottom, rgba($color, 0), $color);
    }
  }

  &__head{
    position: relative;
    z-index: 5;
    padding: $contest-content-space $contest-content-space 0;
  }

  &__body{
    padding: 20px $contest-content-space 40px;
    flex: 1;
    overflow: auto;
    line-height: 20px;
    p{
      margin-bottom: 1rem;
    }
  }

  &__action-button{
    margin-top: 20px !important;
  }
  &__copyright{
      position: absolute;
      right: 1rem;
      top: 1rem;
    opacity: .5;
      z-index: 50;
  }
}
