@import "../../styles/_responsive";
@import "../../styles/_color";

.Avatar {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  z-index: 10;
  background-position: center;
  position: relative;
  color: var(--title-color-1);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__camera-icon {
    position: absolute;
    font-size: 1.2rem !important;
    right: -10px;
    top: calc(50% - 10px);
    z-index: 10;
    display: block;
    border-radius: 3px;
    padding: 0px 1px;
    background-color: var(--secondary-bg-color);
    color: var(--title-color-1);
  }
  &__greeting {
    width:200px;
    z-index: 10;
    color: var(--title-color-1);
  }

  &__profile-silhouette {
    width: 80%;
    height: 80%;
  }

  $this: &;

  &--border {
    &-color {
      &-white,
      &-yellow,
      &-purple {
        border-width: 3px;
        border-style: solid;
      }

      &-yellow {
        border-color: $yellow-1;
      }

      &-purple {
        border-color: #b361ff;
      }

      &-title-color-1 {
        border-color: var(--title-color-1) !important;
      }
    }

    &-size {

      &-thick,
      &-normal,
      &-thin {
        border-style: solid;
      }

      &-thick {
        border-width: 3px;
      }
      &-normal {
        border-width: 2px;
      }
      &-thin {
        border-width: 1px;
      }
    }
  }

  &--shape {
    &-circle {border-radius: 100%;}
    &-soft {border-radius: 3px;}
  }

  &--size {
    &-normal {
      width: 48px;
      height: 48px;
    }

    &-small {
      width: 40px;
      height: 40px;
    }

    &-extra-small {
      width: 30px;
      height: 30px;
    }

    &-2extra-small {
      width: 23px;
      height: 23px;
    }

    &-large {
      width: 70px;
      height: 70px;
    }

    &-larger {
      width: 80px;
      height: 80px;
    }

    &-fit {
      width: 100%;
      height: 100%;
    }
  }

}
.avatar-customCSS {
  margin: auto;
  position: relative;
  overflow: visible;
}
.initial-avatar {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: normal !important;
}