@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin bottomBorder() {
  border-bottom: 1px solid var(--primary-bg-color);
}

@mixin scrollbarStyle() {
  $color: "blue";
  $radius: false;
  $width: 5px;
  $margin: 0px;

  &::-webkit-scrollbar {
    width: $width;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar:horizontal {
    height: $width;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track:horizontal {
    margin: $margin;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track:horizontal {
    @if($radius) {
      border-radius: $width;
    }

    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:horizontal {
    @if($radius) {
      border-radius: $width;
    }

    background-color: var(--scroll-bar-color) !important;
  }
}

.pretty-scroll {
  @include scrollbarStyle();
}

.simplebar-scrollbar::before {
  background-color: var(--scroll-bar-color) !important;
}


@mixin disableScrollbar() {
  &::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }

  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #FF0000;
  }
}

@mixin red-background {
  $text-color: white;
  $red: #BD1717;
  $purple: #D44B46;
  $blue: #3A4F99;
  background-image: linear-gradient(90deg, $red 1%, $purple 50%, $blue 100%);

  &,
  &:hover,
  &--active,
  svg {
    color: $text-color !important;
    fill: $text-color;
  }
}

@mixin yellow-background {
  $text-color: white;
  $dark-yellow: #A6720A;
  $yellow: #FFE070;
  background-image: linear-gradient(90deg, $dark-yellow 1%, $yellow 100%);

  &,
  &:hover,
  &--active,
  svg {
    color: $text-color !important;
    fill: $text-color;
  }
}

@mixin orange-background {
  $orange: var(--orange-color);
  $red: var(--red-color);
  color: white !important;
  background-image: linear-gradient(90deg, $orange 3%, $red 100%);
}

@mixin multilineEllipsis($lines-to-show: 2,
  $width: 100%,
  $font-size: $fontSize-base,
  $line-height: 1.6) {

  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: $width;
  height: $font-size * $line-height * $lines-to-show;
  /* Fallback for non-webkit */
  font-size: $font-size;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*-------linear-gradient-------*/
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction)==false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);

  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value / nth($conversion-factors, index($convertable-units, unit($value))) * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value)=='number'and index('deg''grad''turn''rad', unit($value));

  @return $is-direction or $is-angle;
}

@function legacy-direction($value) {
  @if is-direction($value)==false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (to top : bottom,
    to top right : bottom left,
    to right top : left bottom,
    to right : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom : top,
    to bottom left : top right,
    to left bottom : right top,
    to left : right,
    to left top : right bottom,
    to top left : bottom right);

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/*-----------------------------*/