@import "../../library/src/styles/color";
@import "../../library/src/styles/dimensions";

.LoginModal {
  color: var(--title-color-1-inversed);
  background-position: center center;
  background-size: cover;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  overflow: auto;
  &__form {
    position: relative;
    padding: 2rem;
    width: 100%;
    background: var(--primary-bg-color);
    color: var(--title-color-1);
  }
}
