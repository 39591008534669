@import "../../styles/_color";

$component: Carousel;
.Carousel {
  overflow: hidden;
  position: relative;
  &:not(.h-full) {
    height: 250px !important;
  }

  &__SlideContainer {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__Slide {
    &--hide_right,
    &--hide_left,
    &--hide_top,
    &--hide_bottom {
      transition: transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        visibility 500ms;
      visibility: hidden;
    }

    &--hide_right {
      transform: translateX(100%);
    }
    &--hide_left {
      transform: translateX(-100%);
    }
    &--hide_up {
      transform: translateY(-100%);
    }
    &--hide_down {
      transform: translateY(100%);
    }
    &--show {
      visibility: visible;
      transform: none;
      transition: transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__NavButtons {
    display: none; /* can be reenabled if required */
    position: absolute;
    text-align: center;
    bottom: 15px;
    width: 100%;
    height: auto;
    &--vertical {
      display: flex;
      flex-direction: column;
      bottom: 0;
      width: auto;
      height: 100%;
      align-items: center;
      justify-content: center;
      .#{$component}__NavButton {
      }
    }
  }
  &__NavButton {
    cursor: pointer;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 8px;
    background-color: $white;
    border-radius: 100%;
    opacity: 0.5;
    transition: 500ms;
    &:hover,
    &.ChosenNavButton {
      transition: 500ms;
      opacity: 1;
      background-color: $orange-1;
    }
  }
  &__Progress {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #99603730;
    border-radius: 20px;
    padding: 3px 5px;
    color: #d5e8ff90;
  }
  &__SlideButton {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    position: absolute;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transform-origin: center;
    font-size: 25px;
    &--left {
      left: 10px;
      top: 40%;
    }
    &--right {
      right: 10px;
      top: 40%;
    }
    &--up {
      top: 0;
      left: 50%;
    }
    &--down {
      bottom: 0;
      left: 50%;
    }
  }

  &--clickable {
    cursor: pointer;
  }
}
