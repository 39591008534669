.MiniStreamViewer {
    position: absolute;
    width: 240px;
    aspect-ratio: 16/9;
    z-index: 2000;
    bottom: 30px;
    right: 30px;
    background-color: black;

    &--hide {
        display: none;
    }

    &__video {
       height: 160px;
       background-color: black;
       pointer-events: none;
    }

    video {
        width: 100%;
        height: 100%;
    }
}