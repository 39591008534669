@import "../../library/src/styles/responsive";

.LearnMore {
  min-height: 100vh;
  align-self: center;
  justify-self: flex-start;
  color: black !important;

  @include breakpoints-down(md) {

    table td,
    table th {
      width: 100%;
      display: block;
    }

    table tr {
      margin-bottom: 1rem;
      display: block;
    }
  }

  &__container {
    margin: 25px auto 0;
    background-color: var(--tertiary-bg-color);
    min-height: 300px;
    max-width: 1000px;
    width: auto;
    padding: 15px 35px;

    @include breakpoints-down(xs) {
      padding: 15px 20px;
    }
  }

  &__btn-container {
    &>h1 {
      display: inline-block;
    }
  }

  &__title-btn {
    float: right;
    display: block;
    top: 10px;
  }

  &__title {
    line-height: 70px;
    font-size: 2.7em;
    letter-spacing: -0.05em;
    font-weight: 300;
    color: #686868;
    margin: 0;
  }

  &__subtitle {
    font-size: 1.4em;
    letter-spacing: -0.03em;
    color: #c3c3c3;
    font-weight: 300;
    margin-bottom: 15px;
  }

  &__link {
    cursor: pointer;
  }

  &__block {
    margin: auto 20px;
    text-align: center;

    @include breakpoints-down(xs) {
      margin: auto;
    }

    &>img {
      height: 200px;
      margin: 3rem auto;
    }

    &-title {
      text-align: center;
      font-size: 1.6em;
      line-height: 1em;
      margin-bottom: 15px;
      letter-spacing: -0.05em;
      color: #475074;
    }

    &-text {
      text-align: left;
    }
  }

  &__table-container {
    position: relative;
    padding: 15px 0px 15px 0;
    margin-top: 4rem;
  }

  &__table-image {
    position: absolute;
    background-image: url(https://secureimage.securedataimages.com/images/cams/model_center/payouts/blonde-worker.png);
    background-repeat: no-repeat;
    width: 291px;
    height: 400px;
  }

  &__image-grid {
    min-height: 400px;
  }

  &__table-right {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__tiertable {
    border-spacing: 0px;
    border-collapse: separate;
    width: 100%;

    & th {
      background-color: #676767;
      color: #ffffff;
      padding: 8px 25px;
      text-align: left;
      font-size: 1.2em;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }

    &-head {
      &--padding {
        padding: 8px 35px;
      }

      &--center {
        text-align: center !important;
      }
    }

    & td {
      background-color: #cdcdcd;
      color: #717171;
      padding: 5px 25px;
      text-align: center;
      font-weight: 400;
      font-size: 1.1em;
      white-space: nowrap;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }

    &-btn {
      display: block;
    }

    &-btn-container {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__question-list {
    list-style: none;
    padding-left: 0;

    &>li {
      margin-bottom: 3rem;
    }
  }

  &__question-title {
    padding-left: 50px;
    margin-bottom: 15px;
    position: relative;
    color: #017471;
    font-size: 1.05em;
    letter-spacing: -0.02em;

    &>li {
      margin: 35px 0px 0px;
      padding: 0px;
      color: #333;
      letter-spacing: -0.02em;
    }
  }

  &__question-icon {
    background-color: #017471;
    color: #fff;
    position: absolute;
    left: 0em;
    top: -0.3em;
    padding: 0px 7px 2px;
    border-radius: 50% 50%;
    font-size: 1.2em;
  }

  &__testimonial {
    height: 357px;
    font-style: italic;
    //padding: 0px 330px 0px 110px;
    font-size: 1.2em;
    background-image: url(https://secureimage.securedataimages.com/images/cams/model_center/payouts/az-blonde-bust.png);
    background-repeat: no-repeat;
    background-position: right bottom;

    &>blockquote {
      position: relative;
      left: 10%;
      top: 18%;
      max-width: 50%;
      font-size: 1.1em;
    }
  }

  &__az-banner {
    border: 1px solid #c8c8c8;
    box-shadow: 2px 2px 10px #c9c9c9;

    &-box {
      padding: 20px 0px 25px;
    }
  }

  &__btn-contanier {}

  @include breakpoints-down(xs) {
    &__testimonial {
      &>blockquote {
        background: rgba(255, 255, 255, 0.6);
        padding: 15px;
        left: 0;
        max-width: 100%;
      }
    }
  }
}