
.pillGroup{
  & > div:not(:first-child):not(:last-child){
    border-radius: 0;
  }
    & > div:first-child{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  & > div:last-child{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
}
