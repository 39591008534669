@import "../../../../library/src/styles/color";

.EarningsHeader{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:15px 0 15px;
  margin-bottom: 5px;
  &__Intro {
    margin-bottom: 45px;
  }
  &__Filters{
    padding-top:30px;
  }
  h5 {
    font-size: 2rem;
    font-weight: 700;
    padding-right: 20px;
  }
  p {
    margin-bottom: 10px;
  }
}

.PayoutFilters{
  &__Description{
    color: #999999 !important;
  }
}

.PayoutHeaderRight{
  &__container{
    padding:10px;
  }
  &__TierDescription, &__EmojiDescription{
    font-size: 12px !important;
    font-family: Lato;
    color: var(--title-color-4);

  }
  &__TierValue{
    font-size: 25px ;
    font-family: Anton;
    color: var(--title-color-1);
  }

  &__TotalIncome{
    font-size: 45px ;
    font-family: Anton;
    color: var(--title-color-1);
  }

  &__Emoji{
    max-width: 35px;
    margin: auto;
  }

  &__EmojiDescription{
    text-transform: uppercase;
  }
}

.PayoutDetailsContainer {
  p {
    width: 100%;
    text-align: right;
  }
  &__amount {
    font-weight: bold;
  }
}
