.CustomIcon {
  fill: currentColor;
  color: currentColor;
  padding: 0.1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  z-index: 50;

  &__shadow {


    &::before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      background: #D9D7F1;
      border-radius: 100%;
      z-index: -1;

    }
  }

  &__muted {
    &::before {
      background: rgba(255, 0, 0, 0.171);
    }

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 15px;

      background-color: red;
      transform: rotate(45deg);
    }
  }

  svg {
    fill: currentColor;
    width: 20px;
    height: 20px;

  }

  &.text-xs {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.text-sm {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.text-base {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.text-lg {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  &.text-xl {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.text-2xl {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.text-3xl {
    svg {
      width: 28px;
      height: 28px;
    }
  }

  &.text-4xl {
    svg {
      width: 30px;
      height: 30px;
    }
  }

  &.text-5xl {
    svg {
      width: 32px;
      height: 32px;
    }
  }

  &.text-6xl {
    svg {
      width: 34px;
      height: 34px;
    }
  }

  &.text-7xl {
    svg {
      width: 36px;
      height: 36px;
    }
  }

  &.text-8xl {
    svg {
      width: 38px;
      height: 38px;
    }
  }

  &.text-9xl {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.GoldCrownIcon {
  border-radius: 50%;

  &--color {
    &-gold {
      background-color: #e3b200;
      @apply text-white;
    }

    &-black {
      background-color: #000;
    }
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

.VerifiedIcon {
  border-radius: 50%;

  &--color {
    &-blue {
      background-color: #2ea8ff;
      @apply text-white;
    }

    &-purple {
      background-color: #c869ff;
      @apply text-white;
    }
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

.AddFriendIcon {
  border-radius: 50%;

  &--color {
    &-blue {
      background-color: #2ea8ff;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.ChatBubbleIcon {
  border-radius: 50%;

  &--background {
    &-green {
      background: linear-gradient(135deg, #32a2c7 0%, #60d141 100%);
    }
  }
}

.ProfileSilhouetteIcon {
  color: var(--title-color-1);
  fill: var(--text-main-color);
}



.CloseThickerIcon {
  svg {
    fill: #000000;
    stroke: #fff;
    stroke-width: 3px;
  }
}

.WheelOfFunInactive {
  opacity: 0.3;
}