.NotesDrawer {
    transition: all .4s ease-in-out;
    height: 0px !important;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    min-height: 0px !important;

    >* {
        opacity: 0;
        display: none;
    }

}

.NotesDrawer--active {
    transition: height .4s ease-in-out;
    height: 100%;
    justify-content: flex-start !important;

    top: 0px;
    width: 100%;
    left: 0;

    >* {
        opacity: 1 !important;
        display: block;
    }
}

.Notes-list {
    overflow-x: hidden;

    &--minHeight-3 {
        min-height: calc(100% - 10px);
    }

    &--minHeight-1 {
        min-height: 0%;
    }

    &--minHeight-0 {
        min-height: 500px !important;
    }

}