@import "../../../../library/src/styles/utility";

.MyVirtualGifts {
  &__DropDownMenu {
    min-width: 190px !important;
    margin-bottom: 1rem !important;
  }

  &__GiftContainer{
    height: calc(100vh - 400px - 1rem);
    overflow: auto;
    @include scrollbarStyle();
  }

  &__EmptyState {
    margin: auto;
  }
  &__EmptyStateEmoji {
    max-width: 30px;
    width: 100%;
    display: inline-block;
    margin-left: 5px;
  }
}
