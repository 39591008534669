.TailwindTooltip {
  $this: &;
  max-width: 400px;

  &--chatTooltip {
    position: absolute !important;
    top: -60px !important;
    left: 0px !important;
    z-index: 999999999 !important;
  }

  &.type {
    &-warning {
      @apply bg-yellow-500;
    }

    &-error {
      @apply bg-red-500;
    }

    &-info {
      @apply bg-blue-500;
    }

    &-success {
      @apply bg-green-500;
    }
  }

  &.place-bottom {
    &.type-warning {
      &::after {
        @apply border-b-yellow-500;
      }
    }

    &.type-error {
      &::after {
        @apply border-b-red-500;
      }
    }

    &.type-info {
      &::after {
        @apply border-b-blue-500;
      }
    }

    &.type-success {
      &::after {
        @apply border-b-green-500;
      }
    }
  }

  &.place-top {
    &.type-warning {
      &::after {
        @apply border-t-yellow-500;
      }
    }

    &.type-error {
      &::after {
        @apply border-t-red-500;
      }
    }

    &.type-info {
      &::after {
        @apply border-t-blue-500;
      }
    }

    &.type-success {
      &::after {
        @apply border-t-green-500;
      }
    }
  }

  &.place-left {
    &.type-warning {
      &::after {
        @apply border-l-yellow-500;
      }
    }

    &.type-error {
      &::after {
        @apply border-l-red-500;
      }
    }

    &.type-info {
      &::after {
        @apply border-l-blue-500;
      }
    }

    &.type-success {
      &::after {
        @apply border-l-green-500;
      }
    }
  }

  &.place-right {
    &.type-warning {
      &::after {
        @apply border-r-yellow-500;
      }
    }

    &.type-error {
      &::after {
        @apply border-r-red-500;
      }
    }

    &.type-info {
      &::after {
        @apply border-r-blue-500;
      }
    }

    &.type-success {
      &::after {
        @apply border-r-green-500;
      }
    }
  }


}