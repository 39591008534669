.FeatureSwitch {
    &__Cobrand {
        z-index: 1000;
        position: fixed;
        left: 0;
        bottom:60px;
        padding: 15px;
        min-height: 300px;
        background-color: var(--sidebar-bg-color);
        color: var(--sidebar-text-color);
        width: 380px;
        max-width: 70%;
        max-height: 80vh;
        overflow-y: auto;

        &-select {
            .CamsSelect__input {
                border: 0 !important;
            }
        }

        &-hide-button {
            background-color: var(--title-color-1-inversed);
            width: 30px;
            height: 30px;
            cursor: pointer;
            position: fixed;
            bottom: 30px;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .material-icons {
                color: var(--title-color-1);
            }
        }

        &-item {
            margin: 10px 0;
            width: 100%;
            display: flex ;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
        }
    }

    &__Cookie-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            width: 100px;
        }

        input {
            flex-grow: 1;
        }
    }
}
